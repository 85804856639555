import { Component, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import Instafeed from './instafeed';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-instafeed',
  templateUrl: './instafeed.component.html',
  styleUrls: ['./instafeed.component.scss']
})
export class InstafeedComponent implements AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const feed = new Instafeed({
        get: 'user',
        userId: ['13035818247'],
        limit: 5,
        apiTimeout: 30000,
        accessToken: 'IGQVJXVThvVVYtWjJyck1iWnhQWm5kNFF6dERuakFZAMWRhSVVUTzZA0djNPTmUyWX' +
        'JiUE53Rk1Tc2tyZA1BDbVJaLXhNa09GWHp5UmN1UXB6RmxYa3pxOXlTVHZAfR2M4WjNwRXQyX3FpY2NiZAm15WV9iNwZDZD',
        template: `
          <a href="{{link}}" target="_blank">
            <div>
              <img title="{{caption}}" src="{{image}}">
            </div>
          </a>
        `
      });

      feed.run();
    }
  }
}
