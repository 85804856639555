<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    class="img-hero"
  >

  <div
    class="hero-lead"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5%"
    >
    <!-- <div class="lead-header"></div> -->
    <div class="title">
      <h1>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}</h1>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.subtitle }}</span>
    </div>

    <div class="lead-body">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text }}</p>
    </div>
    <div class="lead-footer">
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-box--husk btn"
      >
        {{ 'Închiriază un spațiu' | translate }}
      </a>
    </div>
  </div>

  <div
    fxHide
    fxShow.gt-md
    class="section-scroll1"
  >
    <div class="scroller">
      <div class="mouse">
        <div class="ball"></div>
      </div>
    </div>
    <span class="hscroll-line"></span>
  </div>
</section>

<section class="tech-details">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="pb-2 title"
    >
        <h3>{{ blockHelper.filterBlocksByKey(blocks, 'tech_details').properties.title }}</h3>
        <hr>
    </div>

    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between start"
      class="mx-auto facilities-cards"
    >
    <ng-container
      *ngFor="let key of ['tech1','tech2','tech3','tech4','tech5','tech6']; let i = index"
    >
      <div
        *ngIf="blockHelper.filterBlocksByKey(blocks, key)"
        fxLayout="column"
        fxFlex="100%"
        fxFlex.gt-sm="49%"
        fxLayoutAlign="start start"
        class="facility-card"
      >
        <div
          class="title"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5%"
        >
          <img [src]="'/assets/png/icon-tech' + (i + 1 ) + '.png' " class="img-fluid">
          <span
            fxFlex
            fxFlex.gt-sm="80%"
          >
            {{ blockHelper.filterBlocksByKey(blocks, key).properties.title }}
          </span>
        </div>
        <div class="tech-text">
          <p class="text-justify white-space-pre-wrap">{{ blockHelper.filterBlocksByKey(blocks, key).properties.text }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  </div>
</section>

<section class="rent-office py-2 px-5">
  <div
    fxLayout="column"
    fxLayoutAlign="center"
    class="py-2 title mb-2"
    fxLayoutGap="1%"
  >
    <h3> {{ blockHelper.filterBlocksByKey(blocks, 'office_spaces').properties.title }} </h3>
    <span> {{ blockHelper.filterBlocksByKey(blocks, 'office_spaces').properties.subtitle }} </span>
  </div>
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-sm="space-evenly stretch"
    fxLayoutGap="3%"
  >
    <div
      fxFlex="100%"
      fxFlex.gt-sm="30%"
      fxLayout="column"
      fxLayoutGap="5%"
      fxLayoutAlign="space-around center"
    >
        <img [src]="blockHelper.filterBlocksByKey(blocks, 'office_rent_img1').image.url" class="img-fluid" draggable="false">
        <img [src]="blockHelper.filterBlocksByKey(blocks, 'office_rent_img2').image.url" class="img-fluid" draggable="false">
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-sm="30%"
      fxLayout="column"
      fxLayoutGap="5%"
      fxLayoutAlign="start start"
    >
      <div
        class="floor-details"
        fxLayout="column"
      >
        <!-- <span class="sqm"> {{ blockHelper.filterBlocksByKey(blocks, 'basement').properties.sqm }} </span> -->
        <span class="fl-name">{{ blockHelper.filterBlocksByKey(blocks, 'basement').properties.name }}</span>
        <div class="text-justify" [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'basement').properties.details"></div>
      </div>

      <div
        class="floor-details"
        fxLayout="column"
      >
        <!-- <span class="sqm"> {{ blockHelper.filterBlocksByKey(blocks, 'ground_floor').properties.sqm }} </span> -->
        <span class="fl-name">{{ blockHelper.filterBlocksByKey(blocks, 'ground_floor').properties.name }}</span>
        <div class="text-justify" [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'ground_floor').properties.details"></div>
      </div>

      <div
        class="floor-details"
        fxLayout="column"
      >
        <!-- <span class="sqm"> {{ blockHelper.filterBlocksByKey(blocks, 'floor_1').properties.sqm }} </span> -->
        <span class="fl-name">{{ blockHelper.filterBlocksByKey(blocks, 'floor_1').properties.name }}</span>
        <div class="text-justify" [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'floor_1').properties.details"></div>
      </div>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-sm="30%"
      fxLayout="column"
      fxLayoutGap="5%"
      fxLayoutAlign="start start"
    >
      <div
        fxLayout="column"
        class="floor-details"
      >
        <!-- <span class="sqm"> {{ blockHelper.filterBlocksByKey(blocks, 'floor_2-6').properties.sqm }} </span> -->
        <span class="fl-name">{{ blockHelper.filterBlocksByKey(blocks, 'floor_2-6').properties.name }}</span>
        <div class="text-justify" [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'floor_2-6').properties.details"></div>
      </div>

      <div
        fxLayout="column"
        class="floor-details"
      >
        <!-- <span class="sqm"> {{ blockHelper.filterBlocksByKey(blocks, 'floor_7').properties.sqm }} </span> -->
        <span class="fl-name">{{ blockHelper.filterBlocksByKey(blocks, 'floor_7').properties.name }}</span>
        <div class="text-justify" [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'floor_7').properties.details"></div>
      </div>
    </div>
  </div>
</section>

<section class="bg-color--husk py-5">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
  >
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'ctas').properties.text }}</p>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%"
      fxLayout="column"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white btn"
      >
        <span>{{ 'Închiriază un spațiu' | translate }}</span>
      </a>
      <a
        [href]="blockHelper.filterBlocksByKey(blocks, 'ctas').properties['presentation-link']"
        target="_blank"
        class="btn-outline--white btn"
      >
        <span>{{ 'Descarcă broșură prezentare' | translate }}</span>
      </a>
    </div>
  </div>
</section>
