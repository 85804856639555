<section class="hero-sec full">
  <app-image-carousel
      *ngIf="sliders"
      [sliders]="sliders"
      class="h-slider"
    >
  </app-image-carousel>
  <!-- <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    [alt]="blockHelper.filterBlocksByKey(blocks, 'hero').properties.title"
    class="img-hero"
  > -->
  <!-- <app-showcase
  [block]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  [blockImage]="blockHelper.filterBlocksByKey(blocks, 'hero')"
  videoUri="https://s3.eu-central-1.amazonaws.com/video.vauntsite.ro/real-sol.ro/home.m4v"
></app-showcase>   -->

  <!-- <div
    class="hero-lead"
    fxLayout="column"
  >
    <h1>
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}
    </h1>
    <p class="lead">
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.lead }}
    </p>
    <a
      [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'hero').properties.cta_url"
      class="btn-box--husk btn"
    >
      <span> {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.cta_name }}</span>
    </a>
  </div> -->
  <div class="section-scroll1">
    <div class="scroller">
      <div class="mouse">
        <div class="ball"></div>
      </div>
    </div>
    <span class="hscroll-line"></span>
  </div>
</section>

<section class="intro pt-2">
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
    class="mx-2"
  >

    <div
      class="left"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlex="45%"
    >
      <div
        fxFill
        class="left-row"
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center"
      >
        <div
          fxFlex.gt-xs="50"
        >
          <img
          [src]="blockHelper.filterBlocksByKey(blocks, 'intro_left1').image.url"
          >
        </div>
        <div
          fxFlex.gt-xs="50"
          class="text-center px-2"
          fxLayout="column"
          fxLayoutGap="5%"
        >
          <span>{{ blockHelper.filterBlocksByKey(blocks, 'intro_left1').properties.text }}</span>
          <a [routerLink]="[basePath, 'offices']" class="btn-line--white">
            <span> {{ 'buttons.see_offices' | translate }} </span>
          </a>
        </div>
      </div>
      <div
        fxFill
        class="left-row"
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="center center"
      >
        <div
          fxFill
          fxFlex="50"
          fxLayout="column"
          fxLayoutGap="5%"
          class="text-center px-2"
        >
          <span>{{ blockHelper.filterBlocksByKey(blocks, 'intro_left2').properties.text }}</span>
          <a [routerLink]="[basePath, 'features']" class="btn-line--white">
            <span>{{ 'buttons.see_features' | translate }}</span>
          </a>
        </div>
        <div
          fxFlex="50"
          fxFill
        >
          <img
            [src]="blockHelper.filterBlocksByKey(blocks, 'intro_left2').image.url"
          >
        </div>
      </div>
    </div>
    <div
      class="right text-center"
      fxFlex="45%">
      <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'intro-right').properties.paragraph1"></p>
      <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'intro-right').properties.paragraph2"></p>
      <a
        [routerLink]="[basePath, 'location']"
        class="btn-box--husk btn btn-box--husk--line"
      >
        <span> {{ 'buttons.see_location' | translate }}</span>
      </a>
    </div>
  </div>
</section>

<section class="awards">
  <div
    class="container"
  >
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between start"
      fxLayoutAlign="center center"
      fxLayoutGap.gt-xs="3%"
      fxLayoutGap="5%"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxFlex.gt-xs="30%"
        fxLayoutGap="5%"
        class="text-center"
      >
        <img src="/assets/png/Shape 1.png" class="img-fluid">
        <span> <b>{{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.first }}</b> </span>
        <span *ngIf="blockHelper.filterBlocksByKey(blocks, 'why_us').properties.first_description">
          {{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.first_description }}
        </span>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="5%"
        fxFlex.gt-xs="30%"
        class="text-center"
      >
        <img src="/assets/png/Shape 2.png" class="img-fluid">
        <span> <b>{{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.second }}</b>  </span>
        <span *ngIf="blockHelper.filterBlocksByKey(blocks, 'why_us').properties.second_description">
          {{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.second_description }}
        </span>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="5%"
        fxFlex.gt-xs="30%"
        class="text-center"
      >
        <img src="/assets/png/Shape 3.png" class="img-fluid">
        <span> <b>{{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.third }}</b> </span>
        <span *ngIf="blockHelper.filterBlocksByKey(blocks, 'why_us').properties.third_description">
          {{ blockHelper.filterBlocksByKey(blocks, 'why_us').properties.third_description }}
        </span>
      </div>
    </div>
  </div>
</section>

<section class="cta">
  <div class="container">
    <div class="video-wrapper ">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/0T7NnZa259s?controls=0&mute=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=0T7NnZa259s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</section>

<section class="cta">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutGap="3%"
    >
      <p class="text-justify husk-color">{{ blockHelper.filterBlocksByKey(blocks, 'cta').properties.text }}</p>
      <a
        [routerLink]="[basePath, 'offices']"
        class="btn-box--husk btn"
      >
        <span> {{ 'buttons.see_offices' | translate }}</span>
      </a>
    </div>
  </div>
</section>

<section class="last">
  <div class="container">
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-evenly strech"
    >
      <div
        fxFlex.gt-xs="50%"
        class="getbrochure"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="5%"
      >
        <img [src]="blockHelper.filterBlocksByKey(blocks, 'brochure').image.url" class="img-fluid">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'brochure').properties.brochure_url"
          class="btn-box--husk btn"
          download="H Victoriei 109"
        >
         <span> {{ 'Descarcă Broșura' | translate }}</span>
        </a>
      </div>
      <div
        class="rentaspace"
        fxLayout="column"
        fxFlex.gt-xs="50%"
        fxLayoutAlign="space-between center"
        fxLayoutGap="5%"
      >
        <div class="title">
          <h3 class="c-husk text-left">{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.title_ln1 }}</h3>
          <h3 class="text-right">{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.title_ln2 }}</h3>
        </div>

        <ul>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text1 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text2 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text3 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text4 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text5 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text6 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text7 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text8 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text9 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text10 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text11 }}</span></li>
          <li><span>{{ blockHelper.filterBlocksByKey(blocks, 'rent_a_space').properties.text12 }}</span></li>
        </ul>
        <a
          [routerLink]="[basePath, 'inquiry']"
          class="btn-box--husk btn"
        >
          <span> {{ 'Închiriază un spațiu' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- <section class="h-projects">
  <div
    class="container"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      class="title"
      [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'projects').properties.title"
    >
    </div>

    <div
      fxLayout.gt-sm="row"
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayoutGap="20%"
      fxLayoutGap.gt-xs="10%"
      class="proj"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="flex-end center"
        class="text-center res"
        fxFlex.xs
        fxFlex.gt-sm="42%"
      >
        <a
        [routerLink]="basePath + '/residential'"
        >
          <img
            src="/assets/img/residential.jpg"
            alt="Hagag Residential Projects"
            class="img-fluid"
          >
        </a>
        <a
          [routerLink]="basePath + '/residential'"
        >
         <span translate>Proiecte Rezidențiale</span>
        </a>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="flex-end center"
        class="text-center comm"
        fxFlex.xs
        fxFlex.gt-sm="42%"
      >
        <a
          [routerLink]="basePath + '/commercial'"
        >
          <img
            src="/assets/img/commercial.jpg"
            alt="Hagag Commercial Projects"
            class="img-fluid"
          >
        </a>
        <a
          [routerLink]="basePath + '/commercial'"
        >
          <span translate>Proiecte comerciale</span>
        </a>
      </div>
    </div>

    <div [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'projects').properties.text">
    </div>

  </div>
</section>

<section class="awards">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10%"
  >
    <div
      fxFlex="50%"
      fxFlex.xs
    >
        <h5> {{ blockHelper.filterBlocksByKey(blocks, 'awards').properties.title }}</h5>
    </div>
    <div
      fxFlex="50%"
      fxFlex.xs
    >
      <ul>
        <li>
          {{ blockHelper.filterBlocksByKey(blocks, 'awards').properties.award1 }}
        </li>
        <li>
          {{ blockHelper.filterBlocksByKey(blocks, 'awards').properties.award2 }}
        </li>
        <li>
          {{ blockHelper.filterBlocksByKey(blocks, 'awards').properties.award3 }}
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="getbrouchure">
  <div
    class="container"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <h5> {{ blockHelper.filterBlocksByKey(blocks, 'downloads').properties.text }}</h5>
    <div
      class="downloads"
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayoutAlign="center center"
    >
      <a
        href="/assets/brochure/H Victoriei 139.pdf"
        class="btn-box--husk btn"
        download="H Victoriei 139"
        fxFlex.gt-xs="30%"
      >
      <span>H Victoriei 139</span>
      </a>
      <a
        href="/assets/brochure/H Eliade.pdf"
        class="btn-box--husk btn"
        download="H Eliade 9"
        fxFlex.gt-xs="30%"
        >
      <span>H Eliade</span>
      </a>
      <a
        href="/assets/brochure/H Pipera Lake.pdf"
        class="btn-box--husk btn"
        download="H Pipera Lake"
        fxFlex.gt-xs="30%"
        >
      <span>H Pipera Lake</span>
      </a>
      <a
        href="/assets/brochure/H Tudor Arghezi 21.pdf"
        class="btn-box--husk btn"
        download="H Tudor Arghezi 21"
        fxFlex.gt-xs="30%"
        >
      <span>H Tudor Arghezi 21</span>
      </a>
      <a
        href="/assets/brochure/H Știrbey Gallery.pdf"
        class="btn-box--husk btn"
        download="H Stirbey Gallery"
        fxFlex.gt-xs="30%"
        >
      <span>H Stirbey Gallery</span>
      </a>
      <a
        href="/assets/brochure/H Victoriei 109.pdf"
        class="btn-box--husk btn"
        download="H Victoriei 109"
        fxFlex.gt-xs="30%"
      >
      <span>H Victoriei 109</span>
      </a>
    </div>
  </div>
</section>

<section class="contactus">

    <div
      class="container"
      fxLayout.gt-xs="row wrap"
      fxLayout="column-reverse"
      fxLayoutAlign.gt-xs="space-around strech"
      fxLayoutAlign="space-around start"

    >
      <div
        fxLayout="column"
        fxFlex="50%"
      >
       <p>
          {{ blockHelper.filterBlocksByKey(blocks, 'contactus').properties.text }}
       </p>

        <a
          [routerLink]="[basePath, 'inquiry']"
          class="btn-box--husk btn"
        >
          <span> {{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
      <div
        fxFlex="30%"
        class="bgimg"
        [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'contactus').properties.title"
      >
      </div>
    </div>
</section> -->
