<app-cookie></app-cookie>

<app-header></app-header>

<div class="site-content" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</div>

<app-footer></app-footer>

<button
  type="button"
  class="scroll-to-top"
  id="scroll_to_top"
  (click)="scrollToTop()"
>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  	 width="444.819px" height="444.819px" viewBox="0 0 444.819 444.819" style="enable-background:new 0 0 372.136 372.136;"
  	 xml:space="preserve">
  <g>
  	<path d="M434.252,208.708L248.387,22.843c-7.042-7.043-15.693-10.564-25.977-10.564c-10.467,0-19.036,3.521-25.697,10.564
  		L10.848,208.708C3.615,215.94,0,224.604,0,234.692c0,9.897,3.619,18.459,10.848,25.693l21.411,21.409
  		c6.854,7.231,15.42,10.855,25.697,10.855c10.278,0,18.842-3.624,25.697-10.855l83.939-83.651v200.998
  		c0,9.89,3.567,17.936,10.706,24.126c7.139,6.184,15.752,9.273,25.837,9.273h36.545c10.089,0,18.698-3.09,25.837-9.273
  		c7.139-6.188,10.712-14.236,10.712-24.126V198.144l83.938,83.651c6.848,7.231,15.413,10.855,25.7,10.855
  		c10.082,0,18.747-3.624,25.975-10.855l21.409-21.409c7.043-7.426,10.567-15.988,10.567-25.693
  		C444.819,224.795,441.295,216.134,434.252,208.708z" data-original="#000000" class="active-path" data-old_color="#ffffff" fill="#ffffff"/>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  </svg>
</button>

<section class="social-bar">
  <ul
    fxLayout="row"
    fxLayoutAlign.gt-md="center center"
    fxLayout.gt-md="column"
    fxLayoutAlign="space-around center"
  >
    <li class="v-block-md">
      <span class="text-uppercase">{{ 'Dezvoltator premiat' | translate }}</span>
    </li>
    <li class="first">
      <a
        href="https://www.facebook.com/H-Victoriei-109-110300540875239/"
        style="padding: 0;"
        target="_blank"
      >
        <img src="/assets/svg/facebook.svg" alt="Facebook Page">
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/hvictoriei109/"
        style="padding: 0;"
        target="_blank"
      >
        <img src="/assets/svg/instagram.svg" alt="Instagram Page">
      </a>
    </li>
    <li>
      <a
        href="https://www.linkedin.com/company/hagag-development-europe"
        style="padding: 0;"
        target="_blank"
      >
        <img src="/assets/svg/linkedin.svg" alt="LinkedIn Page">
      </a>
    </li>
    <li class="last">
      <a
        href="https://www.youtube.com/channel/UCbIypdVWUjLtlX2L-k3PczQ"
        style="padding: 0;"
        target="_blank"
      >
        <img src="/assets/svg/youtube.svg" alt="YouTube Page">
      </a>
    </li>
    <li
      fxHide
      fxShow.gt-md
    >
      <a href="/https://hagageurope.ro/" target="_blank">
        <img src="/assets/svg/vertical.svg" class="img-fluid">
      </a>
    </li>
    <li class="v-block-md">
      <span>{{ 'Dezvoltat de' | translate }}</span>
    </li>
    <li
      fxHide.gt-md
      fxShow
      class="developed-by-h"
    >
      <a href="/https://hagageurope.ro/" target="_blank">
        <img src="/assets/svg/orizontal.svg" class="img-fluid">
      </a>
    </li>
  </ul>
</section>
