import { Component, OnInit, OnDestroy, Injector, Inject } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from '../common-theme/models/index';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss']
})
export class CommercialComponent  extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  subscription: Subscription = new Subscription();
  activeImg: number = 1;
  projects: string[] = [
    'h-victoriei-109',
    'h-tudor-arghezi-21',
    'h-stirbey-gallery'
  ];
  sliders: any[];

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    @Inject('WINDOW') private window: any,
    public injector: Injector
  ) {
    super (injector);
  }
  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)

      this.sliders = this.blocks.filter(obj => {
        return obj.key.startsWith('slider');
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scroll(): void {
    this.window.setTimeout(
      () => {
        this.window.scrollTo(0, 400);
      },
      100
    );
  }

}
