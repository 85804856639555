import {
  Directive,
  HostListener,
  Input
} from '@angular/core';
import { FbqService } from './fbq.service';

@Directive({
  selector: '[appFbqEvent]'
})
export class FbqEventDirective {
  @Input() appFbqEvent: string = null;
  @Input() customData: {} = {};

  constructor(
    private fbqService: FbqService
  ) { }

  @HostListener('click')
  onClick(): void {
    this.validateInputs();
    this.fbqService.sendEvent(this.appFbqEvent, this.customData);
  }

  validateInputs(): void {
    if (!this.appFbqEvent) {
      throw new Error('FbqEventDirective: appFbqEvent is not defined');
    }
    if (this.customData.constructor !== Object) {
      throw new Error('FbqEventDirective: customData is not defined');
    }
  }
}
