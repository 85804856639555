import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from '../common-theme/models/index';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-residential-details',
  templateUrl: './residential-details.component.html',
  styleUrls: ['./residential-details.component.scss']
})
export class ResidentialDetailsComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  subscription: Subscription = new Subscription();

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }
  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      // this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      // this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.description)
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
