<form
  ngNativeValidate
  (submit)="onSubmit()"
>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between strech"
  >
    <ng-template [ngIf]="!success">
      <div
        fxFlex="100%"
        fxFlex.gt-xs="45%"
        class="form-group"
      >
        <input
          [placeholder]="'contact_form.placeholders.first_name' | translate"
          [(ngModel)]="contact.first_name"
          name="firstName"
          required
          #firstName="ngModel"
          type="text"
          class="form-control"
        >
        <app-form-error [errors]="formError.errors.firstName"></app-form-error>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="45%"
        class="form-group"
      >
        <input
          [placeholder]="'contact_form.placeholders.last_name' | translate"
          [(ngModel)]="contact.last_name"
          required
          name="last_name"
          type="text"
          class="form-control"
        >
        <app-form-error [errors]="formError.errors.email"></app-form-error>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="45%"
        class="form-group"
      >
        <input
          [placeholder]="'contact_form.placeholders.phone' | translate"
          [(ngModel)]="contact.phone"
          name="phone"
          required
          type="tel"
          #phone="ngModel"
          class="form-control"
        >
        <app-form-error [errors]="formError.errors.phone"></app-form-error>
      </div>
      <div
        fxFlex="100%"
        fxFlex.gt-xs="45%"
        class="form-group"
      >
        <input
          type="email"
          class="form-control"
          [placeholder]="'contact_form.placeholders.mail' | translate"
          [(ngModel)]="contact.email"
          #email="ngModel"
          name="email"
        >
        <app-form-error [errors]="formError.errors.email"></app-form-error>
      </div>
      <div
        fxFlex="100%"
        class="form-group">
        <textarea
          class="form-control"
          [placeholder]="'contact_form.placeholders.message' | translate"
          [(ngModel)]="contact.message"
          name="message"
          #message="ngModel"
        ></textarea>
        <app-form-error [errors]="formError.errors.message"></app-form-error>
      </div>

      <div
        fxFlex="100%"
        class="form-group"
      >
        <label class="checkbox form-control">
          <input
            type="checkbox"
            name="marketing_accepted"
            required
            [(ngModel)]="contact.marketing_accepted"
          >
          <span
            [innerHTML]="'contact_form.gdpr.message' | translate"
          >
          </span>
          <a
            target="_blank"
            href="/assets/privacy.pdf"
          >
            {{ 'contact_form.gdpr.title' | translate }}
          </a>
        </label>
        <app-form-error [errors]="formError.errors.marketing_accepted"></app-form-error>
      </div>

      <button
        type="submit"
        class="btn-outline--white"
      >
        {{ 'contact_form.submit' | translate }}
      </button>

      <app-re-captcha #recaptcha (captchaResponse)="captchaResponse($event)"></app-re-captcha>
      <app-form-error [errors]="formError.errors['g-recaptcha-response']"></app-form-error>

      <ng-content></ng-content>
    </ng-template>

    <ng-template [ngIf]="success">
      <div
        class="success"
      >
        <h3>
          {{ 'contact_form.success_title' | translate }}
        </h3>
        <p
          [innerHTML]="'contact_form.success_message' | translate"
        >
        </p>
      </div>
      <button
        class="btn-outline--white"
        type="button"
        (click)="success = !success;"
      >
        {{ 'contact_form.success_button' | translate }}
      </button>
    </ng-template>
  </div>
</form>
