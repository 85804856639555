
<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    [alt]="blockHelper.filterBlocksByKey(blocks, 'hero').properties.title"
    class="img-hero"
  >
  <div class="hero-lead-inquiry">
    <div 
      class="lead-header"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="1.5%"
    >
      <i>{{ 'rent_a_space.lead_header.text_1' | translate }}</i>
      <span class="text-center"> {{ 'rent_a_space.lead_header.text_2' | translate }} </span>
    </div>
    <div class="lead-body">
      <ul >
        <li 
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-evenly center"
          fxLayoutAlign="center center"
          fxLayoutGap="3%"
          class="lead-cta mb-2"
        >
          <span> {{ 'rent_a_space.cta.follow_us' | translate }} </span>
          <ul 
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="5%"
          >
            <li class="first">
              <a
                href="https://www.facebook.com/H-Victoriei-109-110300540875239/"
                style="padding: 0;"
                target="_blank"
              >
                <img src="/assets/svg/facebook.svg" alt="Facebook Page" class="img-fluid">
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/hvictoriei109/"
                style="padding: 0;"
                target="_blank"
              >
                <img src="/assets/svg/instagram.svg" alt="Instagram Page" class="img-fluid">
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/hagag-development-europe"
                style="padding: 0;"
                target="_blank"
              >
                <img src="/assets/svg/linkedin.svg" alt="LinkedIn Page" class="img-fluid">
              </a>
            </li>
            <li class="last">
              <a
                href="https://www.youtube.com/channel/UCbIypdVWUjLtlX2L-k3PczQ"
                style="padding: 0;"
                target="_blank"
              >
                <img src="/assets/svg/youtube.svg" alt="YouTube Page" class="img-fluid">
              </a>
            </li>
          </ul>
        </li>
        <li 
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-evenly center"
          fxLayoutAlign="center center"
          fxLayoutGap="3%"
          class="lead-cta"
        >
          <span> {{ 'rent_a_space.cta.call_us' | translate }} </span>
          <a
            href="tel:+40219085"
            fxLayout="row"
            fxLayoutAlign="start center"
            appGtagEvent
            eventCategory="contact"
            eventAction="click"
            eventLabel="phone_click"
            class="contact-item"
            appFbqEvent="Lead"
            [customData]="{content_name: 'phone_click', content_category: 'click'}"
          >
            +4021 9085
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>

<section>
  <div
    class="form"
    fxLayout="column"
  >
    <h1 
      fxLayout="column"
      fxLayoutAlign="center center"
    > 
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'form').properties.title_ln_1 }}</span>
      <span [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'form').properties.title_ln_2"></span>
    </h1>
    <span class="form-lead">{{ blockHelper.filterBlocksByKey(blocks, 'form').properties.text }}</span>
    <app-contact-form-base>
    </app-contact-form-base>
  </div>
</section>

<section class="inquiry-page">
  <div class="container">   
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutGap="5%"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="start center"
      class="mb-2"
    >
      <h3>{{ 'Locația biroului nostru de vânzări' | translate }}</h3>
      <div
        class="footer-contact"
      >
        <a
          [href]="'https://maps.google.com?daddr='
          + '44.44418' + ','
          + '26.09198' + '&amp;ll='"
          target="_blank"
          appGtagEvent
          eventCategory="contact"
          eventAction="click"
          eventLabel="maps_click"
          appFbqEvent="Lead"
          [customData]="{content_name: 'maps_click', content_category: 'click'}"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <img src="./assets/svg/location.svg">
          <div>
            Calea Victoriei 109, {{ 'București' | translate }}
          </div>
        </a>
      </div>
    </div>
    <div>
      <iframe class="gmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.4603916787028!2d26.089858315417825!3d44.44422997910215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff51aab68e8b%3A0x4fbc1d02a12daa2d!2sCalea%20Victoriei%20109%2C%20Bucure%C8%99ti%2C%20Rom%C3%A2nia!5e0!3m2!1sro!2suk!4v1598543580013!5m2!1sro!2suk" width="800" height="600" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
  </div>
</section>

  <!-- <div
    fxLayout="column"
    fxFlex
    fxFlex.gt-xs="35%"
    fxLayoutAlign="start start"
    fxLayoutGap="5%"
    style="padding: 25px;"
  >
    <h4
      class="text-uppercase"
    >{{ 'Informații contact' | translate }}</h4>
    <div
      class="footer-contact"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <a
        href="mailto: office@mc-group.ro"
        fxLayout="row"
        fxLayoutAlign="center center"
        appGtagEvent
        eventCategory="contact"
        eventAction="click"
        eventLabel="email_click"
        class="contact-item"
        appFbqEvent="Lead"
        [customData]="{content_name: 'email_click', content_category: 'click'}"
      >
        <img
          src="./assets/svg/email.svg"
          alt="Hagag Europe"
        >
         office@mc-group.ro
      </a>
    </div>
    <div
     class="footer-contact"
     fxLayout="row"
     fxLayoutAlign="start center"
    >
      <img src="./assets/svg/phone.svg">
      <div fxLayout="row">
        <div fxLayout="column">
          <span style="padding-right: 15px;">
            {{ 'Departament Administrare' | translate }}
          </span>
          <span style="padding-right: 15px;">
            {{ 'Departament Vânzări' | translate }}
          </span>
        </div>
        <div fxLayout="column">
          <a
            href="tel:+40213216095"
            fxLayout="row"
            fxLayoutAlign="start center"
            appGtagEvent
            eventCategory="contact"
            eventAction="click"
            eventLabel="phone_click"
            class="contact-item"
            appFbqEvent="Lead"
            [customData]="{content_name: 'phone_click', content_category: 'click'}"
          >
            +40 21 321 6095
          </a>
          <a
            href="tel:+40219953"
            fxLayout="row"
            fxLayoutAlign="start center"
            appGtagEvent
            eventCategory="contact"
            eventAction="click"
            eventLabel="phone_click"
            class="contact-item"
            appFbqEvent="Lead"
            [customData]="{content_name: 'phone_click', content_category: 'click'}"
          >
            +40 21 9953
          </a>
        </div>
      </div>
    </div>
    <div
      class="footer-contact"
    >
      <a
        [href]="'https://maps.google.com?daddr='
        + '44.461566' + ','
        + '26.080416' + '&amp;ll='"
        target="_blank"
        appGtagEvent
        eventCategory="contact"
        eventAction="click"
        eventLabel="maps_click"
        appFbqEvent="Lead"
        [customData]="{content_name: 'maps_click', content_category: 'click'}"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <img src="./assets/svg/location.svg">
        <div>
          Şoseaua Pavel D. Kiseleff 21
          <br>
          011342, {{ 'București' | translate }}
        </div>
      </a>
    </div>
  </div> -->
