/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instafeed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./instafeed.component";
var styles_InstafeedComponent = [i0.styles];
var RenderType_InstafeedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstafeedComponent, data: {} });
export { RenderType_InstafeedComponent as RenderType_InstafeedComponent };
export function View_InstafeedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "instafeed"], ["id", "instafeed"]], null, null, null, null, null))], null, null); }
export function View_InstafeedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instafeed", [], null, null, null, View_InstafeedComponent_0, RenderType_InstafeedComponent)), i1.ɵdid(1, 4243456, null, 0, i2.InstafeedComponent, [i1.PLATFORM_ID], null, null)], null, null); }
var InstafeedComponentNgFactory = i1.ɵccf("app-instafeed", i2.InstafeedComponent, View_InstafeedComponent_Host_0, {}, {}, []);
export { InstafeedComponentNgFactory as InstafeedComponentNgFactory };
