<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    class="img-hero"
  >

  <div
    class="hero-lead"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5%"
  >
    <!-- <div class="lead-header"></div> -->
    <div class="title">
      <h1>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text_italic }}</h1>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text }}</span>
    </div>

    <div
      class="lead-body"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <span class="c-husk">{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_built_area_text }}</span>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_built_area_digits }}</span>
    </div>

    <div
      class="lead-body"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <span class="c-husk">{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_leasable_area_text }}</span>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_leasable_area_digits }}</span>
    </div>

    <div
      class="lead-body"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <span class="c-husk">{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_leasable_area_2_text }}</span>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.gross_leasable_area_2_digits }}</span>
    </div>

    <div class="lead-footer">
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-box--husk btn"
      >
        {{ 'Închiriază un spațiu' | translate }}
      </a>
    </div>
  </div>

  <div
    fxHide
    fxShow.gt-md
    class="section-scroll1"
  >
    <div class="scroller">
      <div class="mouse">
        <div class="ball"></div>
      </div>
    </div>
    <span class="hscroll-line"></span>
  </div>
</section>

<section class="intro">

  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between start"
    fxLayoutAlign="center center"
  >
    <div
      class="mx-auto"
      fxFlex="49%"
    >
      <div class="text-block">
        <p class="text-justify">{{ blockHelper.filterBlocksByKey(blocks, 'intro').properties.text1 }}</p>
        <div [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'intro').properties.text2"></div>
      </div>
    </div>
    <div
      class="v-line"
      fxHide
      fxShow.gt-sm
    >
      <div class="v-block">
        <span>Scroll for more information</span>
      </div>
    </div>

    <div
      class="image-block"
      fxFlex="49%"
      fxLayout="column"
    >
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'intro').properties.text3 }}</span>
      <img [src]="blockHelper.filterBlocksByKey(blocks, 'intro').image.url" class="img-fluid">
    </div>

  </div>

</section>

<section class="facilities py-5">
  <div
    class="container"
    fxLayout="column"
  >
    <div
      class="title pb-5"
      flexLayout="column"
    >
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'title-facilities').properties.title1 }}</span>
      <h3>{{ blockHelper.filterBlocksByKey(blocks, 'title-facilities').properties.title2 }}</h3>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      class="mx-auto facilities-cards"
    >
      <ng-container
        *ngFor="let key of ['facility1','facility2','facility3','facility4','facility5','facility6']; let i = index"
      >
        <div
          *ngIf="blockHelper.filterBlocksByKey(blocks, key)"
          fxLayout="column"
          fxFlex="100%"
          fxFlex.gt-sm="48%"
          fxLayoutAlign="start start"
          class="facility-card"
          [ngClass]="{'bg--black' : i == 0 || i == 3 || i == 4}"
        >
          <span>{{ blockHelper.filterBlocksByKey(blocks, key).properties.title }}</span>
          <p>{{ blockHelper.filterBlocksByKey(blocks, key).properties.text }}</p>
        </div>
      </ng-container>
      <div
        fxHide
        fxShow.gt-sm
        class="v-line"
      ></div>
    </div>
  </div>
</section>

<section class="col-images">

  <div
    fxShow.gt-sm
    fxHide
    class="overlay min-h"
    [ngStyle]="{'background-image': 'url(' + blockHelper.filterBlocksByKey(blocks, 'imgleft').image.url + ')'}"
  >
  </div>

  <div
    fxShow
    fxHide.gt-sm
    class="img-on-mobile"
  >
    <img [src]="blockHelper.filterBlocksByKey(blocks, 'imgleft').image.url" draggable="false" class="img-fluid">
  </div>

</section>

<section class="text-in-square py-5">
  <div class="container">
    <div
      fxFlex
      fxFlex.gt-xs="75%"
      class="mx-auto bordered"
    >
      <p class="text-justify color-husk">{{ blockHelper.filterBlocksByKey(blocks, 'text-in-square').properties.text }}</p>
    </div>
  </div>
</section>

<section class="bg-color--husk py-5">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
  >
    <div
    fxFlex="100%"
      fxFlex.gt-xs="45%">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'ctas').properties.text }}</p>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%"
      fxLayout="column"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white btn"
      >
        <span>{{ 'Închiriază un spațiu' | translate }}</span>
      </a>
      <a
        [href]="blockHelper.filterBlocksByKey(blocks, 'ctas').properties['presentation-link']"
        class="btn-outline--white btn"
      >
        <span>{{ 'Descarcă broșură prezentare' | translate }}</span>
      </a>
    </div>
  </div>
</section>
