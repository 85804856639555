import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from './../common-theme/models/index';
import { BlockHelperService } from './../common-theme/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent  extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  subscription: Subscription = new Subscription();
  news: string[] = [
    'portofoliul-local-al-hagag-development-europe-depaseste-200-milioane-euro-',
    'palatul-stirbey-va-fi-restaurat-de-hagag-development-europe',
    'hagag-development-europe-finalizeaza-structura-primelor-trei-imobile-din-proiectul-h-pipera-lake',
    'hagag-development-europe-investeste-14-mil-euro-intr-un-nou-proiect-de-spatii-de-birouri-din-bucuresti',
    'israelienii-de-la-hagag-au-inceput-constructia-proiectului-rezidential-h-pipera-lake-o-investitie-de-90-milioane-de-euro',
    'hagag-development-europe-a-obtinut-autorizatia-de-constructie-pentru-h-victoriei-139',
    'global-vision-asigura-serivciile-property-management-pentru-birourile-hagag',
    'dezvoltatorul-imobiliar-hagag-development-europe-finalizeaza-structura-primelor'
  ];

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }
  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
