<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    [alt]="blockHelper.filterBlocksByKey(blocks, 'hero').properties.title"
    class="img-hero"
  >

  <div
    class="hero-lead"
    fxLayout="column"
  >
    <h1>
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}
    </h1>
    <p class="lead">
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.lead }}
    </p>
  </div>
</section>

<section class="proj-cards">
  <div class="container">

    <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    class="mx-auto"
  >
    <ng-template
      ngFor
      let-new
      [ngForOf]="news"
    >
      <app-news-box
        *ngIf="blockHelper.filterBlocksByKey(blocks, new)"
        fxFlex.gt-xs="43%"
        [projectKey]="new"
        [block]="blockHelper.filterBlocksByKey(blocks, new)"
      ></app-news-box>
    </ng-template>
  </div>

  </div>
</section>

<section class="ctas">
  <div
    class="container"
  >
    <div
      fxLayout="column wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="1%"
    >
      <!-- <h5> {{ 'Investeste in exclusivitate' | translate }}</h5>
      <a
        class="btn-outline--white"
        [href]="'/assets/brochure/' + blockHelper.filterBlocksByKey(blocks, 'brochure').properties.brochure + '.pdf'"
        [download]="blockHelper.filterBlocksByKey(blocks, 'brochure').properties.brochure"
      >
        <span>{{ 'Descarcă Broșura' | translate }}</span>
      </a> -->
      <h5> {{ blockHelper.filterBlocksByKey(blocks, 'downloads').properties.text }}</h5>
      <div
        class="downloads"
        fxLayout="column"
        fxLayout.gt-xs="row wrap"
        fxLayoutAlign="center center"
      >
        <a
          href="/assets/brochure/H Victoriei 139.pdf"
          class="btn-outline--white btn"
          download="H Victoriei 139"
          fxFlex.gt-xs="30%"
        >
        <span>H Victoriei 139</span>
        </a>
        <a
          href="/assets/brochure/H Eliade.pdf"
          class="btn-outline--white btn"
          download="H Eliade"
          fxFlex.gt-xs="30%"
          >
        <span>H Eliade 9</span>
        </a>
        <a
          href="/assets/brochure/H Pipera Lake.pdf"
          class="btn-outline--white btn"
          download="H Pipera Lake"
          fxFlex.gt-xs="30%"
          >
        <span>H Pipera Lake</span>
        </a>
        <a
          href="/assets/brochure/H Tudor Arghezi 21.pdf"
          class="btn-outline--white btn"
          download="H Tudor Arghezi 21"
          fxFlex.gt-xs="30%"
          >
        <span>H Tudor Arghezi 21</span>
        </a>
        <a
          href="/assets/brochure/H Știrbey Gallery.pdf"
          class="btn-outline--white btn"
          download="H Stirbey Gallery"
          fxFlex.gt-xs="30%"
          >
        <span>H Stirbey Gallery</span>
        </a>
        <a
          href="/assets/brochure/H Victoriei 109.pdf"
          class="btn-outline--white btn"
          download="H Victoriei 109"
          fxFlex.gt-xs="30%"
        >
        <span>H Victoriei 109</span>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="instafeed-container py-2">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="space-around center"
    >

      <div class="insta-title text-left py-2">
          <h5 class="text-uppercase fs24">{{ 'Fii la curent cu noi pe Instagram' | translate }}</h5>
          <b class="c-husk text-uppercase">#Hagagdevelopmenteurope</b>
      </div>

      <app-instafeed></app-instafeed>
    </div>
  </div>
</section>
<!-- <section class="news">
  <div class="container">
    <div
      fxFlex="column"
      fxFlex="100%"
    >
      <div
        *ngFor="let news of ['news1']"
        class="card-news"
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
      >
        <div
          fxFlex="40%"
        >
          <a [href]="blockHelper.filterBlocksByKey(blocks, news).image.url">
            <img
              [src]="blockHelper.filterBlocksByKey(blocks, news).image.width_560_url"
              [alt]="blockHelper.filterBlocksByKey(blocks, news).image.title"
            >
          </a>
        </div>

        <div
          fxFlex="60%"
        >
          <h2>{{ blockHelper.filterBlocksByKey(blocks, news).properties.title }}</h2>
          <h4>{{ blockHelper.filterBlocksByKey(blocks, news).properties.date }}</h4>
          <div class="text">
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.firstp"></p>
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.qoute"></p>
            <p [innerHTML]="blockHelper.filterBlocksByKey(blocks, news).properties.secondp"></p>
            <a
              *ngIf="blockHelper.filterBlocksByKey(blocks, news).properties.link"
              [href]="blockHelper.filterBlocksByKey(blocks, news).properties.link"
              target="_blank"
            >
              {{ blockHelper.filterBlocksByKey(blocks, news).properties.link_text }}
            </a>
          </div>
        </div>
      </div>

      <div>
        <p class="qoute-text">{{ blockHelper.filterBlocksByKey(blocks, 'qoute').properties.text }}</p>
      </div>
      <div
        class="py-5"
        fxLayout="row"
        fxLayout.sm="column"
        fxLayoutAlign="space-around center"
      >
        <a
          [routerLink]="basePath + '/our-projects/residential'"
          class="btn-box--green"
        >
          {{ 'news.our_projects' | translate }}
        </a>
        <a
          [routerLink]="basePath + '/inquiry'"
          class="btn-outline--white"
        >
          {{ 'news.contact' | translate }}
        </a>
      </div>
    </div>
  </div>
</section> -->
