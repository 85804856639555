<div
  class="proj-box text-center"
  fxLayout="column"
  [routerLink]="[basePath, 'commercial-details', projectKey]"
>
  <img [src]="block.image.url">

  <div
    fxLayout="column wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
    class="logoh"
  >
    <img src="../../../assets/png/H.png" >
    <h3>{{ block.properties.title }}</h3>
  </div>

  <span class="motto c-husk">{{ block.properties.motto }}</span>

  <ul
    fxLayout="row wrap"
    fxLayoutAlign="space-evenly center"
  >
    <li>{{ block.properties.buildings }}</li>
    <li>{{ block.properties.apartments }}</li>
    <li>{{ block.properties.location }}</li>
  </ul>

  <a
    [routerLink]="[basePath, 'commercial-details', projectKey]"
    class="btn-outline--husk"
  >
    <span>{{ 'Află mai multe' | translate }}</span>
  </a>
</div>
