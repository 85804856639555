/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./our-story-dialog.component";
var styles_OurStoryDialogComponent = [];
var RenderType_OurStoryDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OurStoryDialogComponent, data: {} });
export { RenderType_OurStoryDialogComponent as RenderType_OurStoryDialogComponent };
export function View_OurStoryDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""], ["style", "white-space: pre-wrap;"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["align", "end"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).dialogRef.close(i0.ɵnov(_v, 9).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = ""; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.block.properties.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.block.properties.text; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 9).ariaLabel || null); _ck(_v, 8, 0, currVal_3); }); }
export function View_OurStoryDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-our-story-dialog", [], null, null, null, View_OurStoryDialogComponent_0, RenderType_OurStoryDialogComponent)), i0.ɵdid(1, 114688, null, 0, i2.OurStoryDialogComponent, [i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OurStoryDialogComponentNgFactory = i0.ɵccf("app-our-story-dialog", i2.OurStoryDialogComponent, View_OurStoryDialogComponent_Host_0, {}, {}, []);
export { OurStoryDialogComponentNgFactory as OurStoryDialogComponentNgFactory };
