import { BrowserModule, TransferState } from '@angular/platform-browser';
import { LightboxModule } from 'ngx-lightbox';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InstafeedComponent } from './instafeed/instafeed.component';

import { CommonThemeModule } from './common-theme';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ProjectsComponent } from './projects/projects.component';
import { Project1Component } from './projects/project1/project1.component';
import { Project2Component } from './projects/project2/project2.component';
import { Project3Component } from './projects/project3/project3.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ShowcaseComponent } from './shared/showcase/showcase.component';
import { ImageCarouselComponent } from './shared/image-carousel/image-carousel.component';
import { ConceptComponent } from './concept/concept.component';
import { OurStoryDialogComponent } from './concept/our-story-dialog/our-story-dialog.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { ProjectBoxComponent } from './residential/project-box/project-box.component';
import { CommercialBoxComponent } from './commercial/commercial-box/commercial-box.component';
import { NewsComponent } from './news/news.component';
import { ResidentialDetailsComponent } from './residential-details/residential-details.component';
import { CommercialDetailsComponent } from './commercial-details/commercial-details.component';
import { Nume1Component } from './residential-details/nume1/nume1.component';
import { Commercial1Component } from './commercial-details/commercial1/commercial1.component';
import { NewsBoxComponent } from './news/news-box/news-box.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { ArticleComponent } from './news-article/article/article.component';
import { OfficesComponent } from './offices/offices.component';
import { FeaturesComponent } from './features/features.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LocationComponent } from './location/location.component';
import { ListTrackingService } from './common-theme/utils/list-tracking.service';

import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from './common-theme/i18n/translate-browser-loader.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InquiryComponent,
    ProjectsComponent,
    Project1Component,
    Project2Component,
    Project3Component,
    HeaderComponent,
    FooterComponent,
    ShowcaseComponent,
    ImageCarouselComponent,
    ConceptComponent,
    ResidentialComponent,
    CommercialComponent,
    ProjectBoxComponent,
    CommercialBoxComponent,
    NewsComponent,
    NewsBoxComponent,
    ResidentialDetailsComponent,
    CommercialDetailsComponent,
    Nume1Component,
    Commercial1Component,
    NewsArticleComponent,
    ArticleComponent,
    InstafeedComponent,
    OurStoryDialogComponent,
    OfficesComponent,
    FeaturesComponent,
    GalleryComponent,
    LocationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    LightboxModule,
    FlexLayoutModule,
    HttpClientModule,
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    CommonThemeModule,
    MatTabsModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [
    ListTrackingService
  ],
  entryComponents: [OurStoryDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
