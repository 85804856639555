import { Component, Input } from '@angular/core';
import { LocaleService } from './../seo/locale.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// http://www.whirp.org/socialmedia/google/querystrings.htm

@Component({
  selector: 'app-google-map-iframe',
  templateUrl: './google-map-iframe.component.html',
  styles: ['iframe { width: 100%; height: 100%; }']
})
export class GoogleMapIframeComponent {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number = 14;

  _iframeSrc: SafeResourceUrl;
  get iframeSrc(): SafeResourceUrl {
    if (this._iframeSrc)
      return this._iframeSrc;
    if (!this.latitude && !this.longitude) {
      this._iframeSrc = null;
      return null;
    }

    this._iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://maps.google.com/maps?width=700&height=440&hl=' + this.localeService.getCurrentLocale() +
      '&q=' + this.latitude + '%2C' + this.longitude +
      '&ie=UTF8&t=&z=' + this.zoom + '&output=embed'
    );
    return this._iframeSrc;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private localeService: LocaleService
  ) { }
}
