<div
  *ngIf="this.block"
>
  <section class="hero-sec">
    <img
      [src]="this.block.image.url"
      [alt]="this.block.properties.title"
      class="img-hero"
    >

    <div
      class="hero-lead"
      fxLayout="column"
    >
      <h1>
        {{ this.block.properties.title }}
      </h1>
      <div class="text-left">
        <span class="c-husk">{{ this.block.properties.proj_status }}</span>
      </div>
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-box--husk btn"
      >
        <span>{{ 'Contact' | translate }}</span>
      </a>
    </div>
  </section>

  <section class="madeby py-2">
    <div class="container">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="text-center"
      >

        <img src="/assets/png/H.png" class="img-fluid">
        <span>{{ this.block.properties.madeby }}</span>
        <span class="c-husk">{{ this.block.properties.motto }}</span>

      </div>
    </div>

  </section>

  <section class="availability py-2">
    <div
      class="container"
    >
      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-xs="space-between start"
        class="details"
        fxLayoutGap="5%"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-around start"
          fxFlex.gt-xs="55%"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="mb-2"
          >
            <span class="c-husk text-uppercase">
              {{ 'Disponibil pentru:' | translate }}
            </span>
            <span class="text-uppercase"> {{ this.block.properties.availability }}</span>
          </div>
          <div
            [innerHTML]="this.block.properties.project_intro"
            class="mb-2"
          ></div>
          <div
            fxFill
            class="links"
            fxLayout="column"
            fxLayout.gt-xs="row"
            fxLayoutGap="2%"
            fxLayoutAlign.gt-xs="space-around center"
            fxLayoutAlign="space-evenly stretch"
          >
            <a
              fxFlex.gt-xs="50%"
              [href]="'/assets/brochure/' + this.block.properties.brochure + '.pdf'"
              class="btn-box--husk btn"
              [download]="this.block.properties.brochure"
            >
              <span>{{ 'Descarcă Broșura' | translate }}</span>
            </a>
            <a
              fxFlex.gt-xs="50%"
              *ngIf="this.block.properties.website"
              [href]="this.block.properties.website"
              class="btn-outline--white btn"
              target="_blank"
            >
              <span>{{ 'Vezi Website' | translate }}</span>
            </a>
          </div>
        </div>
        <div
          fxFlex.gt-xs="40%"
        >
          <img [src]="'/assets/img/projects/residential/' + this.block.properties.project_intro_img" class="img-fluid">
        </div>
      </div>
    </div>
  </section>

  <section class="presentation-video">

    <div
      class="container"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="4%"
        fxFlex.gt-xs="70%"
        class="mx-auto video"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center stretch"
        >
          <span
            class="c-husk"
            fxHide
            fxShow.gt-xs
            translate
          >
            vezi video de prezentare
          </span>
          <div
            class="video-wrapper"
            [innerHtml]="videoInnerHTML"
          >
          </div>
        </div>
        <div [innerHTML]="this.block.properties.video_text" class="text-center"></div>
      </div>
    </div>

  </section>

  <section class="location">
    <div class="container">
      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayout="center center"
        fxLayoutAlign.gt-xs="space-around center"
        fxLayoutGap="2%"
      >
        <div
          fxFill
          fxFlex.gt-xs="48%"
        >
          <app-google-map-iframe
            *ngIf="block.properties.latitude && block.properties.longitude"
            [latitude]="block.properties.latitude"
            [longitude]="block.properties.longitude"
          ></app-google-map-iframe>
        </div>

        <div
          fxFlex.gt-xs="48%"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div
            class="title"
            [innerHTML]="block.properties.title_location"
          >
          </div>
          <div [innerHTML]="this.block.properties.location"></div>
          <a
            [routerLink]="basePath + '/inquiry'"
            class="btn-outline--husk "
          >
            <span>{{ 'Contactează-ne' | translate }}</span>
          </a>
        </div>
      </div>

    </div>
  </section>

  <section class="specifications">

    <div class="container">
        <h3 class="c-husk text-uppercase mb-2">{{ 'Specificații' | translate }}</h3>
      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="center start"
        fxLayoutAlign.gt-xs="space-evenly center"
      >
        <div class="properties">
          <b class="c-husk text-uppercase">{{ 'Informații generale' | translate }}</b>

          <div
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <ul>
              <ng-container
                *ngFor="let property of ['title', 'proj_status', 'eta', 'madeby', 'motto', 'availability', 'project_intro', 'project_intro_img', 'brochure', 'website', 'video_text', 'location', 'property_type', 'completion', 'layout', 'layout_type', 'subway']"
              >

                <li
                  *ngIf="property === 'property_type'"
                >
                  <span>{{ 'Tip proiect:' | translate }} </span> {{ this.block.properties.property_type }}
                </li>
                <li
                  *ngIf="property === 'completion'"
                >
                  <span>{{ 'Dată finalizare:' | translate }} </span> {{ this.block.properties.completion }}
                </li>
                <li
                  *ngIf="property === 'layout'"
                >
                  <span> {{ 'Dispunere amplasament:' | translate }} </span> {{ this.block.properties.layout }}
                </li>
                <li
                  *ngIf="property === 'layout_type'"
                >
                  <span>{{ 'Amplasament:' | translate }} </span> {{ this.block.properties.layout_type }}
                </li>
                <li
                  *ngIf="property === 'subway'"
                >
                  <span>{{ 'Metrou:' | translate }} </span> {{ this.block.properties.subway }}
                </li>
              </ng-container>
           </ul>
          </div>
        </div>
        <div class="facilities properties">
          <b class="c-husk text-uppercase">{{ 'Facilități' | translate }}</b>
          <div
          fxLayout="column"
          fxLayoutAlign="center start"
        >
          <ul>
            <ng-container
              *ngFor="let property of ['title', 'proj_status', 'eta', 'madeby', 'motto', 'availability', 'project_intro', 'project_intro_img', 'brochure', 'website', 'video_text', 'location', 'property_type', 'completion', 'layout', 'layout_type', 'subway', 'facility1', 'facility2', 'facility3', 'facility4', 'facility5', 'facility6', 'facility7', 'facility8', 'facility9','facility10'] "
            >
              <li
                *ngIf="property === 'facility1'"
              >
                {{ this.block.properties.facility1 }}
              </li>
              <li
                *ngIf="property === 'facility2'"
              >
                {{ this.block.properties.facility2 }}
              </li>
              <li
                *ngIf="property === 'facility3'"
              >
                {{ this.block.properties.facility3 }}
              </li>
              <li
                *ngIf="property === 'facility4'"
              >
                {{ this.block.properties.facility4 }}
              </li>
              <li
                *ngIf="property === 'facility5'"
              >
                {{ this.block.properties.facility5 }}
              </li>
                <li
                *ngIf="property === 'facility6'"
              >
                {{ this.block.properties.facility6 }}
              </li>
              <li
                *ngIf="property === 'facility7'"
              >
                {{ this.block.properties.facility7 }}
              </li>
              <li
                *ngIf="property === 'facility8'"
              >
                {{ this.block.properties.facility8 }}
              </li>
              <li
                *ngIf="property === 'facility9'"
              >
                {{ this.block.properties.facility9 }}
              </li>
              <li
                *ngIf="property === 'facility10'"
              >
                {{ this.block.properties.facility10 }}
              </li>
            </ng-container>
         </ul>
        </div>
        </div>
      </div>

    </div>

  </section>

  <section class="awards">
    <div
      class="container"
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign.gt-xs="space-between center"
      fxLayoutAlign="center start"
    >
      <div
        fxFlex="50%"
        fxFlex.sm
      >
          <h5>
            {{ 'awards.title' | translate }}
          </h5>
      </div>
      <div
        fxFlex="50%"
        fxFlex.sm
      >
        <ul>
          <li>
            {{ 'awards.subtitle1' | translate }}
          </li>
          <li>
            {{ 'awards.subtitle2' | translate }}
          </li>
          <li>
            {{ 'awards.subtitle3' | translate }}
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="getbrouchure">
    <div
      class="container"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h5> {{ 'Dacă vrei să aflii mai multe informații' | translate }}</h5>
      <a
        [routerLink]="[basePath, 'inquiry']"
        class="btn-box--husk btn"
      >
        <span>{{ 'Contactează-ne' | translate }}</span>
      </a>
    </div>
  </section>
</div>
