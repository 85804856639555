import {
  Directive,
  HostListener,
  Input
} from '@angular/core';
import { GtagService } from './gtag.service';

@Directive({
  selector: '[appGtagEvent]'
})

export class GtagEventDirective {
  @Input() eventCategory: string = null;
  @Input() eventAction: string = null;
  @Input() eventLabel: string = null;
  @Input() eventValue: string = null;

  constructor(
    private gtagService: GtagService
  ) { }

  @HostListener('click')
  onClick(): void {
    this.validateInputs();
    this.gtagService.sendEvent(this.eventCategory, this.eventAction, this.eventLabel, this.eventValue);
  }

  validateInputs(): void {
    if (!this.eventCategory) {
      throw new Error('GtagEventDirective: eventCategory is not defined');
    }

    if (!this.eventAction) {
      throw new Error('GtagEventDirective: eventAction is not defined');
    }
  }
}
