<div
  *ngIf="this.block"
>
  <section class="hero-sec">
    <img
      [src]="this.block.image.url"
      [alt]="this.block.properties.title"
      class="img-hero"
    >

    <div
      class="hero-lead"
      fxLayout="column"
    >
      <h1>
        {{ this.block.properties.title }}
      </h1>
      <div class="text-right">
        <span>{{ this.block.properties.date }}</span>
      </div>
      <a
        [routerLink]="basePath + '/news'"
        class="btn-box--husk btn"
      >
        <span translate>Înapoi la articole</span>
      </a>
    </div>
  </section>

  <section class="body-article pb-5">
    <div class="container">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="5%"
      >
        <p
          [innerHTML]="this.block.properties.text"
        ></p>
    </div>
    </div>
  </section>

  <section class="ctas">
    <div
      class="container"
    >
      <div
        fxLayout="column wrap"
        fxLayoutAlign="center center"
        fxLayoutGap="1%"
      >
        <h5> {{ 'Investeste in exclusivitate' | translate }}</h5>
        <a
          [routerLink]="basePath + '/inquiry'"
          class="btn-outline--white"
        >
          <span>{{ 'Contact' | translate }}</span>
        </a>
      </div>
    </div>
  </section>
</div>

<section class="instafeed-container py-2">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="space-around center"
    >

      <div class="insta-title text-left py-2">
          <h5 class="text-uppercase fs24">{{ 'Fii la curent cu noi pe Instagram' | translate }}</h5>
          <b class="c-husk text-uppercase">#Hagagdevelopmenteurope</b>
      </div>

      <app-instafeed></app-instafeed>
    </div>
  </div>
</section>
