import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './concept/concept.component';
import { OfficesComponent } from './offices/offices.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { NewsComponent } from './news/news.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ResidentialDetailsComponent } from './residential-details/residential-details.component';
import { CommercialDetailsComponent } from './commercial-details/commercial-details.component';
import { Nume1Component } from './residential-details/nume1/nume1.component';
import { Commercial1Component } from './commercial-details/commercial1/commercial1.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { ArticleComponent } from './news-article/article/article.component';
import { FeaturesComponent } from './features/features.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LocationComponent } from './location/location.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale',
    component: HomeComponent,
    data: {
      blocks_categories: ['homepage']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/concept',
    component: ConceptComponent,
    data: {
      blocks_categories: ['concept']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/offices',
    component: OfficesComponent,
    data: {
      blocks_categories: ['offices']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/features',
    component: FeaturesComponent,
    data: {
      blocks_categories: ['features']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/gallery',
    component: GalleryComponent,
    data: {
      blocks_categories: ['gallery']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/location',
    component: LocationComponent,
    data: {
      blocks_categories: ['location']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/residential',
    component: ResidentialComponent,
    data: {
      blocks_categories: ['residential']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/commercial',
    component: CommercialComponent,
    data: {
      blocks_categories: ['commercial']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/news',
    component: NewsComponent,
    data: {
      blocks_categories: ['news']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/inquiry',
    component: InquiryComponent,
    data: {
      blocks_categories: ['inquiry']
    },
    resolve: {
      blocks: BlocksService
    }
  },
  {
    path: ':locale/residential-details',
    component: ResidentialDetailsComponent,
    data: {
      blocks_categories: ['residential-details']
    },
    resolve: {
      blocks: BlocksService
    },
    children: [
      {
        path: ':project_key',
        component: Nume1Component
      }
    ]
  },
  {
    path: ':locale/commercial-details',
    component: CommercialDetailsComponent,
    data: {
      blocks_categories: ['commercial-details']
    },
    resolve: {
      blocks: BlocksService
    },
    children: [
      {
        path: ':project_key',
        component: Commercial1Component
      }
    ]
  },
  {
    path: ':locale/news-article',
    component: NewsArticleComponent,
    data: {
      blocks_categories: ['articles']
    },
    resolve: {
      blocks: BlocksService
    },
    children: [
      {
        path: ':project_key',
        component: ArticleComponent
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
