
<section class="hero-sec">
  <app-image-carousel
    *ngIf="sliders"
    [sliders]="sliders"
    class="h-slider"
  >
  </app-image-carousel>
  <!-- <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    [alt]="blockHelper.filterBlocksByKey(blocks, 'hero').properties.title"
    class="img-hero"
  > -->

  <div
    class="hero-lead"
    fxLayout="column"
  >
    <h1>
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}
    </h1>
    <p class="lead">
      {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.lead }}
    </p>
    <a
      [routerLink]="basePath + blockHelper.filterBlocksByKey(blocks, 'hero').properties.cta_url"
      class="btn-box--husk btn"
    >
      <span> {{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.cta_name }}</span>
    </a>
  </div>
</section>

<section class="proj-cards">
  <div class="container">

    <div
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    fxFlex.gt-sm="75%"
    fxFlex
    class="mx-auto"
  >
    <ng-template
      ngFor
      let-project
      [ngForOf]="projects"
    >
      <app-commercial-box
        *ngIf="blockHelper.filterBlocksByKey(blocks, project)"
        fxFlex.gt-xs="45%"
        [projectKey]="project"
        [block]="blockHelper.filterBlocksByKey(blocks, project)"
      ></app-commercial-box>
    </ng-template>
  </div>

  </div>
</section>

<section class="ctas">
  <div
    class="container"
  >
    <div
      fxLayout="column wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="1%"
    >
      <h5> {{ 'Dacă vrei să aflii mai multe informații' | translate }}</h5>
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white"
      >
        <span>{{ 'Contactează-ne' | translate }}</span>
      </a>
    </div>
  </div>
</section>

<section class="instafeed-container py-2">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="space-around center"
    >

      <div class="insta-title text-left py-2">
          <h5 class="text-uppercase fs24">{{ 'Fii la curent cu noi pe Instagram' | translate }}</h5>
          <b class="c-husk text-uppercase">#Hagagdevelopmenteurope</b>
      </div>

      <app-instafeed></app-instafeed>
    </div>
  </div>
</section>
