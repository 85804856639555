import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from '../../common-theme/models/index';
import { BlockHelperService } from '../../common-theme/index';
import { BlocksService } from '../../common-theme/api/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  public block: Block;
  public projectKey: string;
  subscription: Subscription = new Subscription();
  sliders: any[];

  constructor(
    public blockHelper: BlockHelperService,
    public blocksService: BlocksService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.parent.data.subscribe(data => {
      this.blocks = data.blocks;
      this.subscription.add(this.route.params.subscribe(params => {
        this.projectKey = params['project_key'];
        this.block = this.blockHelper.filterBlocksByKey(this.blocks, this.projectKey);
        this.setTitle(this.block.properties.title);

        this.blocksService.getBlocks(this.projectKey).then(blocks => {
          this.sliders = blocks.filter(obj => {
            return obj.key.startsWith('slider');
          });
        })
      }));
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
