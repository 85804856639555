<footer
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="space-between center"
  fxLayoutGap="20px"
>
  <a
    href="/"
    class="footer-branding"
  >
    <img
      src="./assets/png/Victoriei109Logo.png"
      alt="Victoriei 109"
    >
  </a>

  <div
    class="footer-contact"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <a
      [href]="'https://maps.google.com?daddr='
      + '44.44418' + ','
      + '26.09198' + '&amp;ll='"
      target="_blank"
      appGtagEvent
      eventCategory="contact"
      eventAction="click"
      eventLabel="maps_click"
      appFbqEvent="Lead"
      [customData]="{content_name: 'maps_click', content_category: 'click'}"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <img
        src="./assets/svg/location.svg"
        alt="Aesthetic Works"
      >
      Calea Victorei 109,
    </a>
    <span>{{ 'București' | translate }}</span>
  </div>
  <div
    class="footer-contact"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <a
      href="mailto:sales@hagageurope.ro"
      fxLayout="row"
      fxLayoutAlign="center center"
      appGtagEvent
      eventCategory="contact"
      eventAction="click"
      eventLabel="email_click"
      class="contact-item"
      appFbqEvent="Lead"
      [customData]="{content_name: 'email_click', content_category: 'click'}"
    >
      <img
        src="./assets/svg/email.svg"
        alt="Hagag Europe"
      >
      sales@hagageurope.ro
    </a>
  </div>
  <div
    class="footer-contact"
    fxLayout="row"
  >
    <a
      href="tel:+40219085"
      fxLayout="row"
      fxLayoutAlign="center center"
      appGtagEvent
      eventCategory="contact"
      eventAction="click"
      eventLabel="phone_click"
      class="contact-item"
      appFbqEvent="Lead"
      [customData]="{content_name: 'phone_click', content_category: 'click'}"
    >
      <img src="./assets/svg/phone.svg">
      +4021 9085
    </a>
  </div>

  <div
    class="footer-copyright"
    fxLayout="column"
  >
    <span>
      &copy; H Victoriei 109 {{ currentYear }}. {{ "footer.copy" | translate }}
    </span>
    <a
      href="/assets/privacy.pdf"
      target="_blank"
    >
      {{ "footer.privacy" | translate }}
    </a>
  </div>

  <!-- <a
    href="https://aesthetic-works.com/"
    class="footer-branding"
    target="_blank"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <span>Website made by </span>
    <img
      src="./assets/png/aw_logo.png"
      alt="Aesthetic Works"
    >
  </a> -->
</footer>
