<section
  fxHide
  fxShow.gt-sm
  class="hero-sec"
>
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    class="img-hero"
  >
</section>

<div class="gallery">
  <div class="gallery-section">
    <div class="title">
      <h3 translate>
        {{ 'Imagini reale' | translate }}
      </h3>
    </div>

    <div
      fxLayout.gt-xs="row wrap"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="gallery-row">
      <div
        *ngFor="let img of realImages; let i = index; trackBy: listTrackingService.disableUpdate"
        class="gallery-item1"
        (click)="open(realImages, i, $event, 'Photo credits Sabin Prodan')"
        [fxFlex.gt-md]="i%5==0 ? '20%' : i%5==4 ? '24%' : '15%'"
        fxFlex.gt-xs="20%"
        fxFlex="100%"
        fxLayoutGap="1%"
        [ngStyle]="{ 'background-image': 'url(' + img.url + ')' }"
      >
      </div>
    </div>
  </div>
</div>

<div class="gallery">
  <div class="gallery-section">
    <div class="title">
      <h3 translate>
        {{ 'Randări interioare' | translate }}
      </h3>
    </div>

    <div
      fxLayout.gt-xs="row wrap"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="gallery-row">
      <div
        *ngFor="let img of interiorImages; let i = index; trackBy: listTrackingService.disableUpdate"
        class="gallery-item1"
        (click)="open(interiorImages, i, $event)"
        [fxFlex.gt-md]="i%5==0 ? '20%' : i%5==4 ? '24%' : '15%'"
        fxFlex.gt-xs="20%"
        fxFlex="100%"
        fxLayoutGap="1%"
        [ngStyle]="{ 'background-image': 'url(' + img.url + ')' }"
      >
      </div>
    </div>
  </div>
</div>

<div>
  <div class="gallery-section">
    <div class="title">
      <h3 translate>
        {{ 'Randări exterioare' | translate }}
      </h3>
    </div>
    <div
      fxLayout.gt-xs="row wrap"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="gallery-row">
    <div
      *ngFor="let img of exteriorImages; let i = index; trackBy: listTrackingService.disableUpdate"
      class="gallery-item1"
      (click)="open(exteriorImages, i, $event)"
      [fxFlex.gt-md]="i%5==0 ? '20%' : i%5==4 ? '24%' : '15%'"
      fxFlex.gt-xs="20%"
      fxFlex="100%"
      fxLayoutGap="1%"
      [ngStyle]="{ 'background-image': 'url(' + img.url + ')' }"
    >
    </div>
  </div>
  </div>
</div>

<section class="bg-color--husk py-5 last">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
  >
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'ctas').properties.text }}</p>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%"
      fxLayout="column"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white btn"
      >
        <span>{{ 'Închiriază un spațiu' | translate }}</span>
      </a>
      <a
        [href]="blockHelper.filterBlocksByKey(blocks, 'ctas').properties['presentation-link']"
        target="_blank"
        class="btn-outline--white btn"
      >
        <span>{{ 'Descarcă prezentarea' | translate }}</span>
      </a>
    </div>
  </div>
</section>
