import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from '../common-theme/models/index';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  subscription: Subscription = new Subscription();
  sliders: any[];

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public injector: Injector
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)

      this.sliders = this.blocks.filter(obj => {
        return obj.key.startsWith('slider');
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
