<header
  (window:scroll)="onScroll()"
  (window:resize)="onResize()"
  fxLayout="row"
  fxLayoutAlign="center center"
  class="main-header"
>
  <div
    class="main-nav"
    fxLayout.lt-md="row"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex.lt-md
  >
    <div>
      <button
        type="button"
        class="main-menu-burger"
        [class.open]="menuIsOpen"
        (click)="toggleMenu()"
        fxHide.gt-sm
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <a
      class=logo-wrapper
      [routerLink]="basePath"
    >
      <img
        src="./assets/png/Victoriei109Logo.png"
        alt="Victoriei 109"
      >
      <h1>Victoriei 109</h1>
    </a>
    <div
      class="main-menu-wrapper"
    >
      <div
        class="contact-info-desktop"
        fxHide
      >
        <a
          class="separator"
          [attr.href]="'tel:' + websiteService.website.phone"
          *ngIf="websiteService.website.phone"
          appGtagEvent
          eventCategory="contact"
          eventAction="click"
          eventLabel="phone_click"
          class="contact-item"
          appFbqEvent="Lead"
          [customData]="{content_name: 'phone_click', content_category: 'click'}"
        >
          <img
            src="./assets/png/phone.png"
            alt=""
          >
          <span>{{ 'Sună' | translate }} {{ websiteService.website.phone | beautify }}</span>
        </a>
        <a
          class="separator"
          [routerLink]="basePath + '/inquiry'"
        >
          <img
            src="./assets/png/mail.png"
            alt=""
          >
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
  <nav
    class="site-nav"
    [ngClass]="{'open': (menuIsOpen || desktopShowNavMenu)}"
  >
    <div>
      <ul>
        <li *ngFor="let item of navItems" class="item-nav">
          <a
            [routerLink]="basePath + item.link"
            routerLinkActive="active"
            (click)="toggleMenu()"
          >
            <!-- <span *ngIf="item.name == 'Concept'" class="special-char">1</span> -->
            <span translate [innerHTML]="item.name"></span>
          </a>
        </li>
        <li
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <a
            href="mailto:office@mc-group.ro"
            target="_blank"
            class="pr-0 mr-0 nohovereffect"
          >
            <img
              src="./assets/svg/email.svg"
              alt="Hagag Europe"
             >
          </a>
          <a
            href="tel:0219085"
            target="_blank"
            class="pl-0 ml-0"
            appGtagEvent
            eventCategory="contact"
            eventAction="click"
            eventLabel="phone_click"
            class="contact-item"
            appFbqEvent="Lead"
            [customData]="{content_name: 'phone_click', content_category: 'click'}"
          >
            <span>021 9085</span>
          </a>
        </li>
        <li class="language-switcher ">
          <a
            *ngIf="localeService.getCurrentLocale() != 'ro'"
            [href]="localeService.getLocaleUrl('ro')"
            title="română"
          >
            <img
              src="./assets/svg/i18n/ro.svg"
              alt="RO"
            >
          </a>
          <a
            *ngIf="localeService.getCurrentLocale() != 'en'"
            [href]="localeService.getLocaleUrl('en')"
            title="English"
          >
            <img
              src="./assets/svg/i18n/en.svg"
              alt="EN"
            >
          </a>
        </li>
      </ul>
      <div
        class="contact-info-mobile"
        fxHide.gt-sm
      >
        <a
          [href]="'tel:' + websiteService.website.phone"
          appGtagEvent
          eventCategory="contact"
          eventAction="click"
          eventLabel="phone_click"
          class="contact-item"
          appFbqEvent="Lead"
          [customData]="{content_name: 'phone_click', content_category: 'click'}"
        >
          <i class="material-icons">phone</i>
          <span>{{ 'Sună' | translate }}</span>
          <span
            fxHide
            fxShow.gt-xs
          >
            {{ websiteService.website.phone | beautify }}
          </span>
        </a>
        <a [routerLink]="basePath + '/inquiry'">
          <i class="material-icons">mail</i>
          <span>{{ 'Contactează-ne' | translate }}</span>
        </a>
      </div>
    </div>
  </nav>
</header>
