import { Injectable } from '@angular/core';
import { EmbedVideoService } from '../common-theme/index';

@Injectable()
export class GalleryImagesService {
  public years: any = {
    2020: {
      active: false,
      folder: 2020,
      label: 2020,
      months: [
        {
          label: 'septembrie',
          images_count: 8,
          video_urls: [
            'https://www.youtube.com/watch?v=HzhBPLClFjg'
          ]
        },
        {
          images_count: 0,
          video_urls: []
        },
        {
          images_count: 0,
          video_urls: []
        }
      ],
      seasons: [
        {
          label: 'septembrie 2019'
        }
      ]
    }
  };

  constructor(
    public embedService: EmbedVideoService
  ) { }

  getByYear(year: number): any {
    return this.years[year];
  }

  getBySeason(year: number, season: number): any[] {
    const month = season * 3;
    return [this.getByMonth(year, month), this.getByMonth(year, month + 1), this.getByMonth(year, month + 2)];
  }

  getByMonth(year: number, month: number): any[] {
    const monthObect = this.years[year].months[month];

    if (!monthObect.images) {
      monthObect.images = [];
      for (let i = 1; i <= monthObect.images_count; i++)
        monthObect.images.push({url: `/assets/gallery/${year}/${month + 1}/${i}.jpg`});
    }
    if (!monthObect.videos) {
      monthObect.videos = [];
      monthObect.video_urls.forEach(videoUrl => {
        monthObect.videos.push(this.embedService.embed(videoUrl));
      });
    }

    return monthObect;
  }
}
