<div
  *ngIf="sliders.length"
  class="app-slider"
  (mouseenter)="ngOnDestroy()"
  (mouseleave)="initSlider()"
>
  <mat-tab-group
    [selectedIndex]="selectedIndex"
    dynamicHeight
  >
    <mat-tab *ngFor="let slider of sliders">
      <div class="slide-image">
        <img
          [src]="slider.image.url"
          alt="image"
        >
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="center"
        class="slide-text"
      >
        <h2>{{ slider.properties.text1 }}</h2>
        <span>{{ slider.properties.text2 }}</span>
        <a
          [routerLink]="basePath + '/inquiry'"
          class="btn-box--husk btn"
        >
          {{ 'Închiriază un spațiu' | translate }}
        </a>
        <div class="ar">
          <button
          type="button"
          class="slider-arrow left"
          (click)="prev()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          type="button"
          class="slider-arrow right"
          (click)="next()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <!-- <button
    type="button"
    class="slider-arrow left"
    (click)="prev()"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button
    type="button"
    class="slider-arrow right"
    (click)="next()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button> -->
  <div class="slider-bullets">
    <button
      type="button"
      *ngFor="let slider of sliders; let i = index;"
      [ngClass]="{'active': selectedIndex === i}"
      (click)="selectedIndex = i"
    ></button>
  </div>
</div>
