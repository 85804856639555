import { Component, Inject, OnInit } from '@angular/core';
import { Block } from '../../common-theme/models/index';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-our-story-dialog',
  templateUrl: './our-story-dialog.component.html'
})
export class OurStoryDialogComponent implements OnInit {
  public block: Block;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) { }

  ngOnInit(): void {
    this.block = this.dialogData.block
  }
}
