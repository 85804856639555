/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./residential-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./residential-details.component";
import * as i4 from "../common-theme/helper/block-helper.service";
var styles_ResidentialDetailsComponent = [i0.styles];
var RenderType_ResidentialDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResidentialDetailsComponent, data: {} });
export { RenderType_ResidentialDetailsComponent as RenderType_ResidentialDetailsComponent };
export function View_ResidentialDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ResidentialDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-residential-details", [], null, null, null, View_ResidentialDetailsComponent_0, RenderType_ResidentialDetailsComponent)), i1.ɵdid(1, 245760, null, 0, i3.ResidentialDetailsComponent, [i4.BlockHelperService, i2.ActivatedRoute, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResidentialDetailsComponentNgFactory = i1.ɵccf("app-residential-details", i3.ResidentialDetailsComponent, View_ResidentialDetailsComponent_Host_0, {}, {}, []);
export { ResidentialDetailsComponentNgFactory as ResidentialDetailsComponentNgFactory };
