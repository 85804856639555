import { Component, OnInit, OnDestroy, Inject, Injector } from '@angular/core';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Block } from '../common-theme/models/index';
import { BlockHelperService } from '../common-theme/index';
import { ActivatedRoute } from '@angular/router';

import { GalleryImagesService } from './gallery-images.service';
import { ListTrackingService } from '../common-theme/utils/list-tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [ GalleryImagesService ]
})
export class GalleryComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public realImages: any[] = [
    { url: '/assets/gallery/real/1.jpg' },
    { url: '/assets/gallery/real/2.jpg' },
    { url: '/assets/gallery/real/3.jpg' },
    { url: '/assets/gallery/real/4.jpg' },
    { url: '/assets/gallery/real/5.jpg' },
    { url: '/assets/gallery/real/6.jpg' },
    { url: '/assets/gallery/real/7.jpg' },
    { url: '/assets/gallery/real/8.jpg' },
    { url: '/assets/gallery/real/9.jpg' },
    { url: '/assets/gallery/real/10.jpg' },
    { url: '/assets/gallery/real/11.jpg' },
    { url: '/assets/gallery/real/12.jpg' },
    { url: '/assets/gallery/real/13.jpg' },
    { url: '/assets/gallery/real/14.jpg' },
    { url: '/assets/gallery/real/15.jpg' },
    { url: '/assets/gallery/real/16.jpg' },
    { url: '/assets/gallery/real/17.jpg' },
    { url: '/assets/gallery/real/18.jpg' },
    { url: '/assets/gallery/real/19.jpg' },
    { url: '/assets/gallery/real/20.jpg' },
    { url: '/assets/gallery/real/21.jpg' },
    { url: '/assets/gallery/real/22.jpg' },
    { url: '/assets/gallery/real/23.jpg' },
    { url: '/assets/gallery/real/24.jpg' },
    { url: '/assets/gallery/real/25.jpg' },
    { url: '/assets/gallery/real/26.jpg' }
  ];
  public interiorImages: any[] = [
    { url: '/assets/gallery/interior/1.jpg' },
    { url: '/assets/gallery/interior/2.jpg' },
    { url: '/assets/gallery/interior/3.jpg' },
    { url: '/assets/gallery/interior/4.jpg' },
    { url: '/assets/gallery/interior/5.jpg' },
    { url: '/assets/gallery/interior/6.jpg' },
    { url: '/assets/gallery/interior/7.jpg' },
    { url: '/assets/gallery/interior/8.jpg' },
    { url: '/assets/gallery/interior/9.jpg' },
    { url: '/assets/gallery/interior/10.jpg' },
    { url: '/assets/gallery/interior/11.jpg' },
    { url: '/assets/gallery/interior/12.jpg' },
    { url: '/assets/gallery/interior/13.jpg' },
    { url: '/assets/gallery/interior/14.jpg' },
    { url: '/assets/gallery/interior/15.jpg' },
    { url: '/assets/gallery/interior/16.jpg' },
    { url: '/assets/gallery/interior/17.jpg' },
    { url: '/assets/gallery/interior/18.jpg' },
    { url: '/assets/gallery/interior/19.jpg' },
    { url: '/assets/gallery/interior/20.jpg' },
    { url: '/assets/gallery/interior/21.jpg' },
    { url: '/assets/gallery/interior/22.jpg' },
    { url: '/assets/gallery/interior/23.jpg' },
    { url: '/assets/gallery/interior/24.jpg' },
    { url: '/assets/gallery/interior/25.jpg' },
    { url: '/assets/gallery/interior/26.jpg' },
    { url: '/assets/gallery/interior/27.jpg' },
    { url: '/assets/gallery/interior/28.jpg' },
    { url: '/assets/gallery/interior/29.jpg' },
    { url: '/assets/gallery/interior/30.jpg' },
    { url: '/assets/gallery/interior/31.jpg' },
    { url: '/assets/gallery/interior/32.jpg' },
    { url: '/assets/gallery/interior/33.jpg' }
  ];
  public exteriorImages: any[] = [
    { url: '/assets/gallery/exterior/1.JPG' },
    { url: '/assets/gallery/exterior/2.JPG' },
    { url: '/assets/gallery/exterior/3.JPG' },
    { url: '/assets/gallery/exterior/4.JPG' },
    { url: '/assets/gallery/exterior/5.JPG' }
  ];
  public interiorRow1: any[] = [
    { url: '/assets/gallery/interior/1.jpg' },
    { url: '/assets/gallery/interior/2.jpg' },
    { url: '/assets/gallery/interior/3.jpg' },
    { url: '/assets/gallery/interior/4.jpg' },
    { url: '/assets/gallery/interior/5.jpg' }
  ];

  public months: any = [];
  public blocks: Block[];
  sliders: any[];
  translateSubscription: Subscription = new Subscription();

  set currentSeason(currentSeason: number) {
    this._currentSeason = currentSeason;
    this.months = this.imagesService.getBySeason(this.currentYear, currentSeason)
  }
  get currentSeason(): number {
    return this._currentSeason;
  }
  public _currentSeason: number;

  set currentYear(currentYear: number) {
    this._currentYear = currentYear;
    this.currentSeason = 0;
  }
  get currentYear(): number {
    return this._currentYear;
  }
  public _currentYear: number;

  constructor(
    public blockHelper: BlockHelperService,
    public route: ActivatedRoute,
    public imagesService: GalleryImagesService,
    public listTrackingService: ListTrackingService,
    private _lightbox: Lightbox,
    private _lighboxConfig: LightboxConfig,
    public translate: TranslateService,
    public injector: Injector,
    @Inject('WINDOW') private window: any
  ) {
    super(injector)
  }

  ngOnInit(): void {
    // this.setTitle('Actualizări de pe șantierul Central');
   // const today = new Date();
    this.currentYear = 2020;
    // this.currentSeason = Math.floor(today.getMonth() / 4);
    this.currentSeason = 0;

    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)

      this.sliders = this.blocks.filter(obj => {
        return obj.key.startsWith('slider');
      });
    }));
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  public open(images: any[], index: number, event: any, caption: string = ''): void {
    if (event)
      event.stopPropagation();

    if (this.window)
      this._lighboxConfig.positionFromTop = 20;

    const albums = [];

    for (const image of images) {
      const album = {
          src: image.url,
          caption: caption,
          thumb: image.url
      };

      albums.push(album);
    }

    this._lightbox.open(albums, index, { wrapAround: true, showImageNumberLabel: true });
  }
}
