<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    class="img-hero"
  >
  <div 
    class="hero-lead"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5%"
  >
    <!-- <div class="lead-header"></div> -->
    <div class="title">
      <h1>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text_special }}</h1>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text }}</span>
    </div>
  </div>
</section>

<section class="intro">

  <div class="container">
    <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between start"
    fxLayoutAlign="center stretch"
  >
    <div
    fxFlex="100%"
      class="mx-auto"
      fxFlex.gt-sm="49%"
    >
      <div class="text-block">
        <div [innerHTML]="blockHelper.filterBlocksByKey(blocks, 'intro').properties.text_left"></div>
      </div>
    </div>

    <div
      class="image-block"
      fxFlex="100%"
      fxFlex.gt-sm="49%"
      fxLayout="column"
    >
      <!-- <img [src]="blockHelper.filterBlocksByKey(blocks, 'intro').image.url" class="img-fluid"> -->
      <div class="gmaps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.4603916787028!2d26.089858315417825!3d44.44422997910215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff51aab68e8b%3A0x4fbc1d02a12daa2d!2sCalea%20Victoriei%20109%2C%20Bucure%C8%99ti%2C%20Rom%C3%A2nia!5e0!3m2!1sro!2suk!4v1598543580013!5m2!1sro!2suk" width="800" height="240" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
      </div>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'intro').properties.text_right }}</span>
    </div>

  </div>
  </div>

</section>

<section class="accesibility py-2 pb-5">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="title mb-2">
        <h3> {{ blockHelper.filterBlocksByKey(blocks, 'accesibility').properties.title }} </h3>
      </div>
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-sm="center start"
      >
        <ng-container
          *ngFor="let key of ['accesibility_card_1','accesibility_card_2','accesibility_card_3','accesibility_card_4']; let i = index"
        >
          <div
            *ngIf="blockHelper.filterBlocksByKey(blocks, key)"
            fxLayout="column"
            fxFlex="100%"
            fxFlex.gt-sm="25%"
            fxLayoutAlign="center center"
            fxLayoutAlign.gt-sm="center start"
            class="accesibility-card"
          >
            <img [src]="blockHelper.filterBlocksByKey(blocks, key).image.url" class="img-fluid">
            <hr>
            <div class="name">
              <span>
                {{ blockHelper.filterBlocksByKey(blocks, key).properties.name }}
              </span>
            </div>
            <div>
              <p> {{ blockHelper.filterBlocksByKey(blocks, key).properties.text }} </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>


<section class="bg-color--husk py-5">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
  >
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'ctas').properties.text }}</p>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%"
      fxLayout="column"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white btn"
      >
        <span>{{ 'Închiriază un spațiu' | translate }}</span>
      </a>
      <a
        [href]="blockHelper.filterBlocksByKey(blocks, 'ctas').properties['presentation-link']"
        target="_blank"
        class="btn-outline--white btn"
      >
        <span>{{ 'Descarcă broșură prezentare' | translate }}</span>
      </a>
    </div>
  </div>
</section>
