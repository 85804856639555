/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./google-map-iframe.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../seo/locale.service";
var styles_GoogleMapIframeComponent = ["iframe[_ngcontent-%COMP%] { width: 100%; height: 100%; }"];
var RenderType_GoogleMapIframeComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GoogleMapIframeComponent, data: {} });
export { RenderType_GoogleMapIframeComponent as RenderType_GoogleMapIframeComponent };
function View_GoogleMapIframeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "iframe", [["frameborder", "0"], ["marginheight", "0"], ["marginwidth", "0"], ["scrolling", "no"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Vezi localizare pe hart\u0103 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframeSrc; _ck(_v, 0, 0, currVal_0); var currVal_1 = (((("https://maps.google.com?daddr=" + _co.latitude) + ",") + _co.longitude) + "&ll="); _ck(_v, 1, 0, currVal_1); }); }
export function View_GoogleMapIframeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleMapIframeComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframeSrc; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GoogleMapIframeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-google-map-iframe", [], null, null, null, View_GoogleMapIframeComponent_0, RenderType_GoogleMapIframeComponent)), i0.ɵdid(1, 49152, null, 0, i2.GoogleMapIframeComponent, [i3.DomSanitizer, i4.LocaleService], null, null)], null, null); }
var GoogleMapIframeComponentNgFactory = i0.ɵccf("app-google-map-iframe", i2.GoogleMapIframeComponent, View_GoogleMapIframeComponent_Host_0, { latitude: "latitude", longitude: "longitude", zoom: "zoom" }, {}, []);
export { GoogleMapIframeComponentNgFactory as GoogleMapIframeComponentNgFactory };
