import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { SeoAwareBaseComponent } from '../common-theme/seo/seo-aware-base.component';
import { Subscription } from 'rxjs';
import { Block } from './../common-theme/models/index';
import { BlockHelperService } from './../common-theme/index';
import { BlocksService } from './../common-theme/api/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends SeoAwareBaseComponent implements OnInit, OnDestroy {
  public blocks: Block[];
  public projectKey: string;
  subscription: Subscription = new Subscription();
  sliders: any[];

  constructor(
    public blockHelper: BlockHelperService,
    public blocksService: BlocksService,
    public route: ActivatedRoute,
    public injector: Injector
  ) {
    super (injector);
  }

  ngOnInit(): void {
    this.subscription.add(this.route.data.subscribe(data => {
      this.blocks = data.blocks;
      this.setTitle(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.title)
      this.setMetaDescription(this.blockHelper.filterBlocksByKey(this.blocks, 'seo').properties.meta)

      this.sliders = this.blocks.filter(obj => {
        return obj.key.startsWith('slider');
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
