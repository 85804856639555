import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { GtagService } from './analytics/gtag.service';
import { FbqService } from './analytics/fbq.service';

// Components
import { GoogleMapIframeComponent } from './gmaps/google-map-iframe.component';
import { FormErrorComponent } from './contact-form-base/form-error.component';
import { ContactFormBaseComponent } from './contact-form-base/contact-form-base.component';
import { ReCaptchaComponent } from './contact-form-base/re-captcha.component';

// API
import {
  ApiCallService,
  ApiLoadingService,
  BlockService,
  BlocksService,
  ContactService,
  WebsiteService
} from './api/index';

import { LocaleService } from './seo/locale.service'

import { BlockHelperService } from './helper/block-helper.service';
import { EmbedVideoService } from './helper/embed-video.service';

// Tooltips

// Dirctives
import { GtagEventDirective } from './analytics/gtag-event.directive';
import { FbqEventDirective } from './analytics/fbq-event.directive';

// Translate
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from './i18n/translate-browser-loader.service';
import { TransferState } from '@angular/platform-browser';

// Pipes
import { TruncatePipe } from './pipes/index';
import { BeautifyPhonePipe } from './pipes/beautify-phone.pipe';
import { ParseFloatPipe } from './pipes/parse-float.pipe';

import { CookieComponent } from './cookie/cookie.component';

/* tslint:disable:only-arrow-functions */
export function getWindow(): any {
  return (typeof window !== 'undefined') ? window : null;
}

export function getScreen(): any {
  return (typeof screen !== 'undefined') ? screen : null;
}

/* tslint:enable */

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    FlexLayoutModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    })
  ],
  providers: [
    ApiCallService,
    ApiLoadingService,
    BlockService,
    BlocksService,
    LocaleService,
    ContactService,
    WebsiteService,
    BlockHelperService,
    EmbedVideoService,
    GtagService,
    FbqService,
    { provide: 'WINDOW', useFactory: getWindow },
    { provide: 'SCREEN', useFactory: getScreen }
  ],
  declarations: [
    // Pipes
    TruncatePipe,
    BeautifyPhonePipe,
    ParseFloatPipe,

    FormErrorComponent,
    ContactFormBaseComponent,
    GoogleMapIframeComponent,
    ReCaptchaComponent,
    CookieComponent,

    GtagEventDirective,
    FbqEventDirective
  ],
  exports: [
    TruncatePipe,
    BeautifyPhonePipe,
    ParseFloatPipe,

    FormErrorComponent,
    ContactFormBaseComponent,
    ReCaptchaComponent,
    CookieComponent,
    GoogleMapIframeComponent,

    GtagEventDirective,
    FbqEventDirective
  ]
})
export class CommonThemeModule { }
