/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./news-box.component";
var styles_NewsBoxComponent = [i0.styles];
var RenderType_NewsBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsBoxComponent, data: {} });
export { RenderType_NewsBoxComponent as RenderType_NewsBoxComponent };
export function View_NewsBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "news-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["fxLayoutAlign", "center center"], ["fxLayoutGap", "1%"], ["fxlayout", "row"]], null, null, null, null, null)), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i3.Directionality, i4.StyleUtils, [2, i2.LayoutGapStyleBuilder], i4.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "wm-lr"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i2.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "c-husk"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "read-more-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "a", [["class", "btn-box--husk"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 3), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "1%"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 3, 0, currVal_1); var currVal_3 = "column"; _ck(_v, 7, 0, currVal_3); var currVal_9 = _ck(_v, 14, 0, _co.basePath, "news-article", _co.projectKey); _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.block.properties.date; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.block.properties.title; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.block.image.url; var currVal_6 = _co.block.properties.title; _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 13).target; var currVal_8 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Afl\u0103 mai multe")); _ck(_v, 16, 0, currVal_10); }); }
export function View_NewsBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-news-box", [], null, null, null, View_NewsBoxComponent_0, RenderType_NewsBoxComponent)), i1.ɵdid(1, 180224, null, 0, i8.NewsBoxComponent, [i1.Injector], null, null)], null, null); }
var NewsBoxComponentNgFactory = i1.ɵccf("app-news-box", i8.NewsBoxComponent, View_NewsBoxComponent_Host_0, { block: "block", projectKey: "projectKey" }, {}, []);
export { NewsBoxComponentNgFactory as NewsBoxComponentNgFactory };
