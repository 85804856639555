import { Component, Input } from '@angular/core';
import { LocaleAwareBaseComponent } from '../../common-theme/index';
import { Block } from '../../common-theme/models/index';

@Component({
  selector: 'app-commercial-box',
  templateUrl: './commercial-box.component.html',
  styleUrls: ['./../../residential/project-box/project-box.component.scss']
})
export class CommercialBoxComponent extends LocaleAwareBaseComponent {
  @Input() block: Block;
  @Input() projectKey: string;
}
