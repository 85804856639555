<section class="hero-sec">
  <img
    [src]="blockHelper.filterBlocksByKey(blocks, 'hero').image.url"
    class="img-hero"
  >

  <div 
    class="hero-lead"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5%"
    >
    <!-- <div class="lead-header"></div> -->
    <div class="title">
      <h1>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.title }}</h1>
      <span>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.subtitle }}</span>
    </div>

    <div class="lead-body">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'hero').properties.text }}</p>
    </div>
    <div class="lead-footer">
      <a 
        [routerLink]="basePath + '/inquiry'"  
        class="btn-box--husk btn"
      > 
        {{ 'Închiriază un spațiu' | translate }}
      </a>
    </div>
  </div>

  <div 
    fxHide
    fxShow.gt-md
    class="section-scroll1"
  >
    <div class="scroller">
      <div class="mouse">
        <div class="ball"></div>
      </div>
    </div>
    <span class="hscroll-line"></span>
  </div>
</section>

<section class="floored-plans">
  <div class="container">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="pb-2"
    >
        <h3>{{ blockHelper.filterBlocksByKey(blocks, 'intro-plans').properties.title }}</h3>
        <span>{{ blockHelper.filterBlocksByKey(blocks, 'intro-plans').properties.subtitle }}</span>
    </div>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab [label]="blockHelper.filterBlocksByKey(blocks, 'tab1').properties.name" class="btn-outline--white">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'tab1').image.url" 
          target="_blank"
        >
          <img [src]="blockHelper.filterBlocksByKey(blocks, 'tab1').image.url" draggable="false" class="img-fluid"> 
        </a>
      </mat-tab>
      <mat-tab [label]="blockHelper.filterBlocksByKey(blocks, 'tab2').properties.name" class="btn-outline--white">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'tab2').image.url" 
          target="_blank"
        >
          <img [src]="blockHelper.filterBlocksByKey(blocks, 'tab2').image.url" draggable="false" class="img-fluid"> 
        </a>
      </mat-tab>
      <mat-tab [label]="blockHelper.filterBlocksByKey(blocks, 'tab3').properties.name" class="btn-outline--white">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'tab3').image.url" 
          target="_blank"
        >
          <img [src]="blockHelper.filterBlocksByKey(blocks, 'tab3').image.url" draggable="false" class="img-fluid"> 
        </a>
      </mat-tab>
      <mat-tab [label]="blockHelper.filterBlocksByKey(blocks, 'tab4').properties.name" class="btn-outline--white">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'tab4').image.url" 
          target="_blank"
        >
          <img [src]="blockHelper.filterBlocksByKey(blocks, 'tab4').image.url" draggable="false" class="img-fluid"> 
        </a>
      </mat-tab>
      <mat-tab [label]="blockHelper.filterBlocksByKey(blocks, 'tab5').properties.name" class="btn-outline--white">
        <a
          [href]="blockHelper.filterBlocksByKey(blocks, 'tab5').image.url" 
          target="_blank"
        >
         <img [src]="blockHelper.filterBlocksByKey(blocks, 'tab5').image.url" draggable="false" class="img-fluid"> 
        </a>
      </mat-tab>
    </mat-tab-group>
  </div>

</section>

<section class="bg-color--husk py-5">
  <div
    class="container"
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutGap="5%"
  >
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%">
      <p>{{ blockHelper.filterBlocksByKey(blocks, 'ctas').properties.text }}</p>
    </div>
    <div
      fxFlex="100%"
      fxFlex.gt-xs="45%"
      fxLayout="column"
      fxLayoutGap="5%"
    >
      <a
        [routerLink]="basePath + '/inquiry'"
        class="btn-outline--white btn"
      >
        <span>{{ 'Închiriază un spațiu' | translate }}</span>
      </a>
      <a
        [href]="blockHelper.filterBlocksByKey(blocks, 'ctas').properties['presentation-link']"
        target="_blank"
        class="btn-outline--white btn"
      >
        <span>{{ 'Descarcă broșură prezentare' | translate }}</span>
      </a>
    </div>
  </div>
</section>
