import { Routes } from '@angular/router';
import { BlocksService } from './common-theme/api/index';
import { HomeComponent } from './home/home.component';
import { ConceptComponent } from './concept/concept.component';
import { OfficesComponent } from './offices/offices.component';
import { ResidentialComponent } from './residential/residential.component';
import { CommercialComponent } from './commercial/commercial.component';
import { NewsComponent } from './news/news.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ResidentialDetailsComponent } from './residential-details/residential-details.component';
import { CommercialDetailsComponent } from './commercial-details/commercial-details.component';
import { Nume1Component } from './residential-details/nume1/nume1.component';
import { Commercial1Component } from './commercial-details/commercial1/commercial1.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { ArticleComponent } from './news-article/article/article.component';
import { FeaturesComponent } from './features/features.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LocationComponent } from './location/location.component';
const ɵ0 = {
    blocks_categories: ['homepage']
}, ɵ1 = {
    blocks_categories: ['homepage']
}, ɵ2 = {
    blocks_categories: ['concept']
}, ɵ3 = {
    blocks_categories: ['offices']
}, ɵ4 = {
    blocks_categories: ['features']
}, ɵ5 = {
    blocks_categories: ['gallery']
}, ɵ6 = {
    blocks_categories: ['location']
}, ɵ7 = {
    blocks_categories: ['residential']
}, ɵ8 = {
    blocks_categories: ['commercial']
}, ɵ9 = {
    blocks_categories: ['news']
}, ɵ10 = {
    blocks_categories: ['inquiry']
}, ɵ11 = {
    blocks_categories: ['residential-details']
}, ɵ12 = {
    blocks_categories: ['commercial-details']
}, ɵ13 = {
    blocks_categories: ['articles']
};
const routes = [
    {
        path: '',
        component: HomeComponent,
        data: ɵ0,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale',
        component: HomeComponent,
        data: ɵ1,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/concept',
        component: ConceptComponent,
        data: ɵ2,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/offices',
        component: OfficesComponent,
        data: ɵ3,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/features',
        component: FeaturesComponent,
        data: ɵ4,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/gallery',
        component: GalleryComponent,
        data: ɵ5,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/location',
        component: LocationComponent,
        data: ɵ6,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/residential',
        component: ResidentialComponent,
        data: ɵ7,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/commercial',
        component: CommercialComponent,
        data: ɵ8,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/news',
        component: NewsComponent,
        data: ɵ9,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/inquiry',
        component: InquiryComponent,
        data: ɵ10,
        resolve: {
            blocks: BlocksService
        }
    },
    {
        path: ':locale/residential-details',
        component: ResidentialDetailsComponent,
        data: ɵ11,
        resolve: {
            blocks: BlocksService
        },
        children: [
            {
                path: ':project_key',
                component: Nume1Component
            }
        ]
    },
    {
        path: ':locale/commercial-details',
        component: CommercialDetailsComponent,
        data: ɵ12,
        resolve: {
            blocks: BlocksService
        },
        children: [
            {
                path: ':project_key',
                component: Commercial1Component
            }
        ]
    },
    {
        path: ':locale/news-article',
        component: NewsArticleComponent,
        data: ɵ13,
        resolve: {
            blocks: BlocksService
        },
        children: [
            {
                path: ':project_key',
                component: ArticleComponent
            }
        ]
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: ''
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
