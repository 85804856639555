/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-carousel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/flex-layout/extended";
import * as i12 from "./image-carousel.component";
var styles_ImageCarouselComponent = [i0.styles];
var RenderType_ImageCarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCarouselComponent, data: {} });
export { RenderType_ImageCarouselComponent as RenderType_ImageCarouselComponent };
function View_ImageCarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 25, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["class", "slide-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 19, "div", [["class", "slide-text"], ["fxLayout", "column"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(8, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [["class", "btn-box--husk btn"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "ar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["class", "slider-arrow left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(20, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "button", [["class", "slider-arrow right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(24, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_right"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = "column"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "center"; _ck(_v, 8, 0, currVal_2); var currVal_7 = (_co.basePath + "/inquiry"); _ck(_v, 14, 0, currVal_7); _ck(_v, 20, 0); _ck(_v, 24, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image.url; _ck(_v, 5, 0, currVal_0); var currVal_3 = _v.context.$implicit.properties.text1; _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.properties.text2; _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 14).target; var currVal_6 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("\u00CEnchiriaz\u0103 un spa\u021Biu")); _ck(_v, 15, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 20).inline; var currVal_10 = (((i1.ɵnov(_v, 20).color !== "primary") && (i1.ɵnov(_v, 20).color !== "accent")) && (i1.ɵnov(_v, 20).color !== "warn")); _ck(_v, 19, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 24).inline; var currVal_12 = (((i1.ɵnov(_v, 24).color !== "primary") && (i1.ɵnov(_v, 24).color !== "accent")) && (i1.ɵnov(_v, 24).color !== "warn")); _ck(_v, 23, 0, currVal_11, currVal_12); }); }
function View_ImageCarouselComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedIndex = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵdid(4, 933888, null, 0, i11.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i7.ɵNgClassImpl, [6, i7.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.selectedIndex === _v.context.index)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, (_co.selectedIndex === _v.context.index)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ImageCarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "app-slider"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.ngOnDestroy() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.initSlider() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["dynamicHeight", ""]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG]], { dynamicHeight: [0, "dynamicHeight"], selectedIndex: [1, "selectedIndex"] }, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCarouselComponent_2)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slider-bullets"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCarouselComponent_3)), i1.ɵdid(8, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; var currVal_3 = _co.selectedIndex; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.sliders; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.sliders; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ImageCarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageCarouselComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliders.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageCarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-carousel", [], null, null, null, View_ImageCarouselComponent_0, RenderType_ImageCarouselComponent)), i1.ɵdid(1, 245760, null, 0, i12.ImageCarouselComponent, [i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageCarouselComponentNgFactory = i1.ɵccf("app-image-carousel", i12.ImageCarouselComponent, View_ImageCarouselComponent_Host_0, { intervalToSlide: "intervalToSlide", sliders: "sliders" }, {}, []);
export { ImageCarouselComponentNgFactory as ImageCarouselComponentNgFactory };
