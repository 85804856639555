<div class="news-box"
>
  <div
    fxlayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="1%"
  >
    <span class="wm-lr">
      {{ block.properties.date }}
    </span>

    <div
      fxLayout="column"
    >
      <h3 class="c-husk">{{ block.properties.title }}</h3>
      <img [src]="block.image.url" [alt]="block.properties.title">

      <div
        class="read-more-link"
      >
        <a
          [routerLink]="[basePath, 'news-article', projectKey]"
          class="btn-box--husk"
        >
          <span>{{ 'Află mai multe' | translate }}</span>
        </a>
      </div>
    </div>
  </div>

</div>
